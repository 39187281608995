import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import SearchInput from "../../components/SearchInput/SearchInput";
import "./SearchPage.scss";
import Fetch from "../../components/Fetch/Fetch";
import ScreenParser from "../../parser/ScreenParser";
import Loading from "../../components/Loading/Loading";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";

class SearchPage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      query: undefined
    }
  }

  onSearch = (query) => {
    this.setState({ query })
  }

  render() {
    const { query } = this.state;
    const { store: { language } } = this.props;
    const url = query && `${process.env.REACT_APP_TEMPLATE_URL}/search/${query}`;

    return (
      <Fetch 
        url={url} 
        addMiddlewareHeaders 
        options={{ credentials: "include" }} 
        authzBeforeRequest
        >
        {(loading, errors, data) => {
          let screen = ScreenParser(data && data.templateScreen || {}, language);
          document.title = screen.title.en || screen.title;

          return (
            errors ?
            <ErrorOverlay /> :
            <div className="search-page" style={{backgroundColor: screen.backgroundColor}}>
              <div className="search-page-title">Search REELZ Now for Shows and Episodes</div>
              <SearchInput onChange={this.onSearch} error={!loading && query && !screen.components.length} />
              { loading && <Loading /> }
              { query && screen.components }
            </div>
          )
        }}
      </Fetch>
    )
  }
};

export default compose(withRouter, withStore)(SearchPage);

import { TOGGLE_MENU_VISIBILITY, HIDE_SIGN_IN, SELECT_PROVIDER, CHANGE_LANGUAGE, CLEAR, HANDLE_LOGOUT, TOGGLE_MAINTENANCE } from "../actions";
import { DISPLAY_PROVIDER_DIALOG, SET_AUTHENTICATION_STATUS, SELECTED_PROVIDER, ENTITLEMENT_LOADED, SET_METADATA_STATUS } from "../AdobeAuthActions"
import { StoreState, GenericAction, StorageField, storageKey } from "../types";

export function updateLocalStorage(key: StorageField, value?: any) {
  if (value == undefined)
    localStorage.removeItem(storageKey(key));
  else
    localStorage.setItem(storageKey(key), typeof value == "object" ? JSON.stringify(value) : value);
}

export function getLocalStorageItem(key: StorageField) {
  return localStorage.getItem(storageKey(key))
}

export function clearLocalStorage() {
  for (let field in StorageField) {
    localStorage.removeItem(storageKey(field));
  }
}

export function initLocalStorage(values) {
  for (let field in StorageField) {
    let key = field.toLowerCase();
    let keyInStorage = storageKey(field);
    let valueInStorage = localStorage.getItem(keyInStorage);

    if (key in values && !valueInStorage) {
      updateLocalStorage(StorageField[StorageField[field]], values[key]);
    }
  }
}

export const initialState: StoreState = {
  language: "en",
  menuOpen: false,
  displaySignIn: false,
  entitlementLoaded: false,
  authenticationStatus: -1,
  providers: [],
  selectedProvider: undefined,
  inMaintenance: false,
  maintenanceMessage: undefined,
  pinDialogData: undefined,
  userType: undefined
};

export default function reducer (state: StoreState = initialState, action: GenericAction) {
  console.log('REDUCER: ' + action.type, action.data)
  switch (action.type) {
    case ENTITLEMENT_LOADED:
      return { ...state, entitlementLoaded: action.data.entitlementLoaded, userType: 'adobe' }
    case SET_AUTHENTICATION_STATUS:
      const isAuthenticated = action.data.isAuthenticated;
      return {
        ...state,
        authenticationStatus: isAuthenticated,
        userType: isAuthenticated ? action.data.userType : undefined
      };
    case TOGGLE_MENU_VISIBILITY:
      return { ...state, menuOpen: !state.menuOpen };
    case DISPLAY_PROVIDER_DIALOG:
      return { ...state, displaySignIn: true, menuOpen: false, providers: action.data.providers, pinDialogData: action.data.pinDialogData };
    case HIDE_SIGN_IN:
      const signInIframe = document.getElementById("mvpddiv");
      if (signInIframe) signInIframe.remove();
      return { ...state, displaySignIn: false };
    case SELECTED_PROVIDER:
      return { ...state, selectedProvider: action.data.selectedProvider };
    case CHANGE_LANGUAGE:
      updateLocalStorage(StorageField.Language, action.data.language);
      return { ...state, language: action.data.language };
    case HANDLE_LOGOUT:
      const logout = fetch(`${process.env.REACT_APP_MIDDLEWARE_URL}/client/logout`, {credentials: "include"})
      logout.then(response => {
        updateLocalStorage(StorageField.LogoutFlag, 1);
        window["getAccessEnabler"]().logout();
      });
      return state;
    case TOGGLE_MAINTENANCE:
      return { ...state, inMaintenance: action.data.isActive, maintenanceMessage: action.data.message };
    case SET_METADATA_STATUS:
      console.log()
      sessionStorage.setItem(action.data.key, action.data.result);
      return {...state, [action.data.key]: action.data.result}
    default:
      return state;
  }
};

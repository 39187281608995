import { ENTITLEMENT_LOADED, SET_AUTHENTICATION_STATUS } from './AdobeAuthActions';

export const CHANGE_CATALOG = "CHANGE_CATALOG";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const TOGGLE_MENU_VISIBILITY = "TOGGLE_MENU_VISIBILITY";
export const DISPLAY_SIGN_IN = "DISPLAY_SIGN_IN";
export const HIDE_SIGN_IN = "HIDE_SIGN_IN";
export const SELECT_PROVIDER = "SELECT_PROVIDER";
export const HANDLE_LOGOUT = "HANDLE_LOGOUT";
export const TOGGLE_MAINTENANCE = "TOGGLE_MAINTENANCE";

export const CLEAR = "CLEAR";

export const changeCatalog = (catalog, callback = undefined) => ({
  type: CHANGE_CATALOG,
  data: { catalog, callback }
});

export const changeLanguage = (language) => ({
  type: CHANGE_LANGUAGE,
  data: { language }
});

export const clearStore = () => ({
  type: CLEAR,
  data: null
});

export const toggleMenu = () => ({
  type: TOGGLE_MENU_VISIBILITY
})

export const setAuthenticationStatus = ({ isAuthenticated, errorCode, userType }) => {
  console.log('status called', { isAuthenticated, errorCode, userType })
  return ({
    type: SET_AUTHENTICATION_STATUS,
    data: { authenticationStatus: isAuthenticated, errorCode, userType }
  })
}

export const hideSignIn = () => ({
  type: HIDE_SIGN_IN,
  data: {
    displaySignIn: false
  }
})

export const selectProvider = (provider) => ({
  type: SELECT_PROVIDER,
  data: {
    selectedProvider: provider
  }
})

export const handleLogout = () => ({
  type: HANDLE_LOGOUT
});

export const toggleMaintenance = (isActive, message) => ({
  type: TOGGLE_MAINTENANCE,
  data: { isActive, message }
});

import React from "react";
import { withRouter } from "react-router-dom";
import "./ShowsPage.scss";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import Grid from '../../components/Grid/Grid';
import Filter from "../../components/Filter/Filter";
import Fetch from "../../components/Fetch/Fetch";
import ScreenParser, { Component } from "../../parser/ScreenParser";
import Sort from "../../components/Sort/Sort";
import Loading from "../../components/Loading/Loading";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";

class ShowsPage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      genreFilter: undefined
    }
  }

  onGenreChange = (genreFilter) => {
    this.setState({ genreFilter });
  }

  render() {
    const { store: { language } } = this.props;
    const { genreFilter } = this.state;

    return (
      <Fetch 
        url={process.env.REACT_APP_SHOWSPAGE_URL} 
        addMiddlewareHeaders 
        authzBeforeRequest
        >
        {(loading, errors, data) => {
          let screen = ScreenParser(data && data.templateScreen || {}, language);
          let numComponents = screen.componentProps.length;
          let components: any[] = [];
          let defaultFilter;

          document.title = screen.title.en || screen.title;

          for(let i=0; i<numComponents; i++) {
            let props = screen.componentProps[i];

            if(props.type == Component.Filter) {
              defaultFilter = props.options[0].value;
              components.push(<Filter key={i} {...props} onChange={this.onGenreChange} />)
            }
            // This is because the Sort component is a prop in the Grid component. It assumes any sort with a grid proceeding
            // it should be connected
            else if(props.type == Component.Sort && i<numComponents-1 && screen.componentProps[i+1].type == Component.Grid) {
              let gridComponentProps = screen.componentProps[i+1];
              let gridProps = {
                ...gridComponentProps,
                header: genreFilter,
                tiles: gridComponentProps.tiles.filter(tile => {
                  return genreFilter == defaultFilter ? true : tile.genres.includes(genreFilter)
                })
              };

              let sort = <Sort {...props} />
              let grid = <Grid key={i} {...gridProps} sort={sort} filter={genreFilter} />
              components.push(grid);
              i++;
            }
            else {
              components.push(React.cloneElement(screen.components[i], { key: i }));
            }
          }

          return (
            errors ?
            <ErrorOverlay /> :
            <div className="ShowsPage" style={{backgroundColor: screen.backgroundColor}}>
              { loading && <Loading /> }
              { components }
            </div>
          )
        }}
      </Fetch>
    )
  }
};

export default compose(withRouter, withStore)(ShowsPage);

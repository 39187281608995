import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import Fetch from "../../components/Fetch/Fetch";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import Loading from "../../components/Loading/Loading";
import "./InfoPage.scss";

class InfoPage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    let selected = window.location.hash && this.getMenuOptions().map(o => o.value).includes(window.location.hash) ?
      window.location.hash : this.getMenuOptions()[0].value

    this.state = { selected };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.hash != this.props.location.hash && this.state.selected != this.props.location.hash) {
      this.onSelection(this.props.location.hash)
    }
  }

  getMenuOptions = () => {
    return [
      { value: "#about", label: "About Reelz Now" },
      { value: "#privacy", label: "Privacy Policy" },
      { value: "#terms", label: "Terms of Service" },
      { value: "#support", label: "Support" },
      { value: "#legal", label: "Legal Notices" },
    ]
  };

  getSelectionUrl = () => {
    const { selected } = this.state;

    switch(selected) {
      case "#about": return process.env.REACT_APP_ABOUT_URL;
      case "#privacy": return process.env.REACT_APP_PRIVACY_URL;
      case "#terms": return process.env.REACT_APP_TERMS_URL;
      case "#support": return process.env.REACT_APP_SUPPORT_URL;
      case "#legal": return process.env.REACT_APP_LEGAL_URL;
    }
  }

  onSelection = (selected) => {
    const { history } = this.props;
    history.replace(`${window.location.pathname}${selected}`);
    this.setState({ selected })
  }

  render() {
    return (
      <Fetch url={this.getSelectionUrl()} addMiddlewareHeaders authzBeforeRequest>
        {(loading, errors, data) => {
          const { selected } = this.state;
          const screen = data && data.staticScreen || {};
          const menuOptions = this.getMenuOptions();

          return (
            errors ?
            <ErrorOverlay /> :
            <div className="InfoPage" style={{backgroundColor: screen.backgroundColor}}>
              { loading && <Loading /> }
              <div className="InfoPage-menu">
                <ul>
                  { menuOptions.map((option, index) => {
                      let className = selected == option.value ? "selected" : undefined
                      let onClick = () => this.onSelection(option.value);
                      return <li key={index} className={className} onClick={onClick}>{option.label}</li>
                    })
                  }
                </ul>
              </div>
              <div className="InfoPage-header">{screen.header}</div>
              <div className="InfoPage-body" dangerouslySetInnerHTML={{__html: screen.body}} />
            </div>
          )
        }}
      </Fetch>
    )
  }
};

export default compose(withRouter, withStore)(InfoPage);

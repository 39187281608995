import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import { SVGImage } from "../../assets/images";
import classnames from "classnames";
import LiveSlide from "../Slide/LiveSlide";
import Slide from "../Slide/Slide";
import "./Hero.scss";

const HeroArrow: React.SFC<any> = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);

class Hero extends React.Component<any,any> {
  slider;

  pauseSlider = () => {
    this.slider.slickPause();
  }

  resumeSlider = () => {
    this.slider.slickPlay();
  }

  render() {
    const { autoAdvance, autoAdvanceSpeed, showPagination, slides } = this.props;
    const className = classnames("Hero", this.props.className);
    const settings = {
      dots: showPagination,
      autoplay: autoAdvance,
      autoplaySpeed: autoAdvanceSpeed,
      infinite: true,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      pauseOnFocus: true,
      prevArrow: <HeroArrow onMouseOver={this.pauseSlider} onMouseLeave={this.resumeSlider}><img src={SVGImage.PreviousArrow} /><img src={SVGImage.PreviousArrowHover} /></HeroArrow>,
      nextArrow: <HeroArrow onMouseOver={this.pauseSlider} onMouseLeave={this.resumeSlider}><img src={SVGImage.NextArrow} /><img src={SVGImage.NextArrowHover} /></HeroArrow>
    }
    return (
      <div className={className}>
        <Slider {...settings} ref={node => (this.slider = node)}>
          {
            slides.map((slide, index) => {
              switch(slide.slideType) {
                case "live_slide_component":
                  return <LiveSlide key={index} {...slide} />
                default:
                  return <Slide key={index} {...slide} />
              }
            })
          }
        </Slider>
      </div>
    )
  }
};

export default compose(withRouter, withStore)(Hero);

const adobe = {
  env: "staging", // or 'production'
  get entitlement() {
    if (adobe.env == "staging") {
      return "https://entitlement.auth-staging.adobe.com/entitlement/v4/AccessEnabler.js";
    } else {
      return "https://entitlement.auth-staging.adobe.com/entitlement/AccessEnabler.js"
    }
  }
}

export default {
  localStoragePrefix: "Reelz",
  auth0: {
    domain: "reelz-admin.auth0.com",
    clientID: "ERFDZS6T-11VKi-lrPzB8zn6eqCgVOdL"
  },
  cognito: {
    region: 'us-east-1',
    UserPoolId: 'us-east-1_4LENxzPMC',
    ClientId: '53qq0no3il92fm0h1kve80pqh'
  },
  adobe: adobe
};

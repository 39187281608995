import React from "react";
import { withRouter } from "react-router";

class ScrollToTop extends React.Component<any, any> {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname ||
        this.props.location.hash !== prevProps.location.hash) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);

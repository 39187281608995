import React from "react";
import Hero from "../components/Hero/Hero";
import Carousel from "../components/Carousel/Carousel";
import Grid from "../components/Grid/Grid";
import Filter from "../components/Filter/Filter";
import Sort from "../components/Sort/Sort";
import KeyArt from "../components/KeyArt/KeyArt";
import PlayerDetail from "../components/PlayerDetail/PlayerDetail";

enum Component {
  Hero,
  Carousel,
  Grid,
  Filter,
  Sort,
  KeyArt,
  PlayerDetail
};

const childrenTileMapper = (component, pageId) => {
  return component.children.map(child => {
    const content = child.content_configuration.content[0];

    const header = content.tile.header;
    const subHeader = content.tile.subHeader;
    const duration = content.duration;
    const showDuration = pageId === "show" ? true : false;
    const image = content.tile.image.url;
    const url = child.click_event.path.replace("{id}", content.id)
    const position = content.position;
    const badge = content.tile.badges && content.tile.badges[0];
    const dateAdded = content.createdAt;
    const genres = content.genres;

    return {
      header,
      subHeader,
      duration,
      showDuration,
      image,
      badge,
      url,
      position,
      dateAdded,
      genres
    }
  });
}

const contentConfigurationTileMapper = (component, pageId) => {
  return component.content_configuration.content.map(child => {
    const header = child.tile.header;
    const subHeader = child.tile.subHeader;
    const duration = child.duration;
    const showDuration = pageId === "show" ? true : false;
    const image = child.tile.image.url;
    const url = component.child_configuration.click_event.path.replace("{id}", child.id);
    const position = child.position;
    const badge = child.tile.badges && child.tile.badges[0];
    const dateAdded = child.createdAt;
    const genres = child.genres;

    return {
      header,
      subHeader,
      duration,
      showDuration,
      image,
      badge,
      url,
      position,
      dateAdded,
      genres
    }
  })
}

const getComponentProps = (components, language, pageId) => {
  return components.map((component, index) => {
    let content;
    switch (component.view_type) {
      case "basic_carousel_component":
        return {
          type: Component.Hero,
          autoAdvance: component.auto_advance && component.auto_advance > 0,
          autoAdvanceSpeed: component.auto_advance && component.auto_advance * 1000,
          showPagination: component.show_pagination || true,
          slides: component.children.map(child => {
            const content = child.content_configuration.content[0];
            const liveStreamUrl = child.content_configuration.live_stream_url;

            const slideType = child.view_type.toLowerCase();
            const contentType = content.type;
            const color = child.background_color;
            const url = child.click_event.path.replace("{id}", content.id);
            const type = content.type.toLowerCase();
            const header = content.slide.header;
            const subHeader = content.slide.subHeader;
            const description = content.slide.description;
            const image = content.slide.image.url;
            const info = content.type == "EPISODE" &&
              [`S${content.seasonNumber}, E${content.episodeNumber}`, content.metadata.rating, content.runtime]
                .filter(i => i != undefined)
                .join(" | ")

            return {
              type,
              slideType,
              contentType,
              url,
              color,
              header,
              subHeader,
              description,
              liveStreamUrl,
              image,
              info
            }
          })
        }

      case "titled_carousel_component":
      case "basic_grid_component":
        return {
          type: component.view_type == "titled_carousel_component" ? Component.Carousel : Component.Grid,
          header: component.title[language],
          tiles: (component.children && component.children.length !== 0) ?
            childrenTileMapper(component, pageId) :
            contentConfigurationTileMapper(component, pageId)
        }

      case "filter_component":
        return {
          type: Component.Filter,
          header: component.title[language],
          options: component.filter_options.map(option => ({ label: option.title[language], value: option.method }))
        }

      case "sort_component":
        return {
          type: Component.Sort,
          header: component.title[language],
          options: component.sort_options.map(option => ({ label: option.title[language], value: option.method }))
        }

      case "divided_slide_component":
        content = component.content_configuration.content[0];
        return {
          type: Component.KeyArt,
          header: content.slide.header,
          // subHeader: content.slide.subHeader,
          description: content.slide.description,
          image: content.slide.image.url,
          info: content.metadata && content.metadata.rating,
          color: component.background_color,
          previewVideoId: content.previewVideoId
        }

      case "player_detail_component":
        content = component.content_configuration.content[0];
        const info = [`S${content.seasonNumber}, E${content.episodeNumber}`, content.metadata.rating, content.runtime]
          .filter(i => i != undefined)
          .join(" | ");

        return {
          type: Component.PlayerDetail,
          id: content.extId,
          contentId: content.id,
          header: content.slide.header,
          subHeader: content.slide.subHeader,
          nextEpisode: content.nextEpisode,
          info,
          position: content.position,
          description: content.slide.description,
          image: content.tile.image && content.tile.image.url
        }

      default:
        return null;
    }
  }).filter(props => {
    if(props == null)
      return false;

    switch (props.type) {
      case Component.Hero:
        return props.slides.length;

      case Component.Carousel:
      case Component.Grid:
        return props.tiles.length;

      case Component.Filter:
      case Component.Sort:
        return props.options;

      default:
        return true;
    }
  })
}

const getComponents = (components, language, pageId) => {
  return getComponentProps(components, language, pageId).map((fullProps, index) => {
    const { type, ...props } = fullProps;
    switch (type) {
      case Component.Hero:
        return <Hero key={index} {...props} />
      case Component.Carousel:
        return <Carousel key={index} {...props} />
      case Component.Grid:
        return <Grid key={index} {...props} />
      case Component.Filter:
        return <Filter key={index} {...props} />
      case Component.Sort:
        return <Sort key={index} {...props} />
      case Component.KeyArt:
        return <KeyArt key={index} {...props} />
      case Component.PlayerDetail:
        return <PlayerDetail key={index} {...props} />
    }
  })
}

const getPageInfo = (screen, language) => {
  return {
    title: screen.title && screen.title[language] || "Reelz",
    id: screen.id,
    backgroundColor: screen.background_color
  }
};

export default function parse(screen, language) {
  const components = screen && screen.components || [];
  const pageId = screen && screen.id || "";

  return {
    ...getPageInfo(components, language),
    componentProps: getComponentProps(components, language, pageId),
    components: getComponents(components, language, pageId)

  }
};

export { Component };

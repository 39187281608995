import React from "react";
import brightcovePlayerLoader from '@brightcove/player-loader';
import "./PlayerDetail.scss";
import MultiLineDiv from "../MultiLineDiv/MultiLineDiv";

import VideoMessageOverlay from "../VideoMessageOverlay/VideoMessageOverlay";

import AuthManager from "../../utilities/authUtilities"
import ConcurrencyManager from "../../utilities/adobeConcurrency"

import { flowRight as compose } from 'lodash';
import { withStore, StorageField, getLocalStorageItem, updateLocalStorage } from "../../store";
import { FacebookButtons, TweetButton } from "../SocialButtons/SocialButtons";

class PlayerDetail extends React.Component<any, any> {
  player;
  COUNTDOWN_THRESHOLD = 10;
  CONTINUITY_FREQUENCY = 10 * 1000;
  options;
  concurrencyManager;
  constructor(props) {
    super(props)
    this.state = {
      authzStatus: -1,
      error: false,
      errorMsg: ''
    }
  }
  componentDidMount() {
    const { store } = this.props;
    this.options = {
      controlBar: {
        volumePanel: {
          inline: false,
          vertical: true
        }
      }
    };
    // if (false) {
    if (store.authenticationStatus > 0) {
      this.checkAuthz(store.userType);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    const { store } = nextProps;
    // if (false) {
    if (store && store.authenticationStatus > 0 &&
      store.userType &&
      store.selectedProvider && store.selectedProvider.MVPD &&
      nextState.authzStatus === -1) {
      this.checkAuthz(store.userType);
    }
    return true
  }
  brightcovePlayer = (options) => {
    return loadBrightcovePlayer.call(this, this.props, options);
  }
  // Passes the userType, e.g. "adobe" to the AuthManager
  checkAuthz = (userType) => {
    // sets the checkedAuthz state immediately, then performs the authz check
    this.setState({
      authzStatus: 0
    }, () => {
      // Callback currently only handles Adobe events. If we ever add additional ones
      // then we can extend it or abstract it furthur
      AuthManager.CheckAuthorization(userType, this.props.store.selectedProvider.MVPD, this.props.id).then(async ({ eventType, data }) => {
        // Of the events/callbacks Adobe's checkAuthz function invokes, we are only interested in
        // the two below.
        switch (eventType) {
          case "SET_TOKEN":
            let response = await fetch(`${process.env.REACT_APP_MIDDLEWARE_URL}/authenticate-adobe`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              credentials: "include",
              body: JSON.stringify({ token: data.data.token, adobeID: this.props.store.userID || sessionStorage.getItem("userID") })
            });
            // let responseJSON = await response.json();
            // document.cookie = "jwt=%22" + responseJSON.token + "%22"; // Store jwt to cookie
            this.setState({ authzStatus: 1 }, () => this.brightcovePlayer(this.options));
            break;
          case "TOKEN_REQUEST_FAILED":
            this.setState({
              errorMsg: data.data.requestErrorCode,
              error: true
            });
            break;
        }
      });
    })
  }
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
      this.concurrencyManager.stop();
    }
  }
  onSignIn = () => {
    window["getAccessEnabler"]().getAuthentication();
  };
  render() {
    const { header, subHeader, image, info, description, store: { authenticationStatus } } = this.props;
    const { authzStatus, error } = this.state;
    return (
      <div className="PlayerDetail">
        <div className="playerWrapper">
          <VideoMessageOverlay
            backgroundImageUrl={image}
            authorizationStatus={authzStatus}
            authenticationStatus={authenticationStatus}
            error={error}
            errorMsg={this.state.errorMsg}
            onSignIn={this.onSignIn}
          />
          <div className="player"></div>
        </div>
        <div className="metadata">
          <div className="header">{header}</div>
          <div className="subheader">{subHeader}</div>
          <div className="info">{info}</div>
          <MultiLineDiv className="description">{description || " "}</MultiLineDiv>
          <div className="social-buttons">
            <FacebookButtons url={window.location.href} />
            <TweetButton url={window.location.href} text={`Watch ${header} on Reelz!`} />
          </div>
        </div>
      </div>
    )
  }
};

function loadBrightcovePlayer(this: PlayerDetail, props, options) {
  const { id, contentId, header, nextEpisode, position } = props;
  return brightcovePlayerLoader({
    refNode: ".player",
    refNodeInsert: 'append',
    accountId: process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID,
    playerId: 'default',
    embedId: 'default',
    videoId: 'ref:' + id,
    options: options
  }).then((success) => {
    const resourceId = sessionStorage.getItem("resourceId");
    const packageId = (resourceId === "BReelz") ? "blue-3" : (resourceId === "OReelz" ? "orange-1" : "reelz");
    const userId = sessionStorage.getItem("userID") !== "null" ?
    sessionStorage.getItem("userID") : sessionStorage.getItem("upstreamUserID");
    this.concurrencyManager = new ConcurrencyManager({
      provider: sessionStorage.getItem("mvpd"),
      userId,
      package: packageId
    });
    this.player = success.ref;
    const thisPlayer = this.player;
    let controlBarEl_ = this.player.controlBar.el_,
      volumePanelEl_ = document.getElementsByClassName("vjs-volume-panel")[0],
      currentTimeEl_ = document.getElementsByClassName("vjs-current-time")[0],
      durationEl_ = document.getElementsByClassName("vjs-duration")[0],
      timeDividerEl_ = document.getElementsByClassName("vjs-time-divider")[0],
      progressBarEl_ = document.getElementsByClassName("vjs-progress-control")[0],
      spacerEl_ = document.getElementsByClassName("vjs-spacer")[0],
      originalCCButtonEl_ = document.getElementsByClassName("vjs-subs-caps-button")[0],
      seekBarEl_ = this.player.controlBar.progressControl.seekBar.bar.el_,
      tooltipEl_ = this.player.controlBar.progressControl.seekBar.bar.timeTooltip && this.player.controlBar.progressControl.seekBar.bar.timeTooltip.el_,
      volumeBarEl_ = this.player.controlBar.volumePanel.volumeControl.volumeBar.bar.el_,
      progressHandleEl_ = document.createElement("div"),
      volumeHandleEl_ = document.createElement("div"),
      volumeControEl_ = this.player.controlBar.volumePanel.volumeControl.el_,
      volumePanelTriangleEl_ = document.createElement("div"),
      ccButtonEl_ = document.createElement("div"),
      nextOverlayEl_ = document.createElement("div"),
      nextOverlayThumbnailEl_ = document.createElement("div"),
      nextOverlayInfoPanelEl_ = document.createElement("div"),
      nextOverlayReelzLogoEl_ = document.createElement("div"),
      nextOverlayUpNextEl_ = document.createElement("div"),
      nextOverlayCountDownEl_ = document.createElement("div"),
      nextOverlaySeriesTitleEl_ = document.createElement("div"),
      nextOverlayEpisodeTitleEl_ = document.createElement("div"),
      // Decide if the current video should autoplay.
      shouldAutoPlay = getLocalStorageItem(StorageField.Autoplay) || "false",
      // Decide if nextUpOverlay should be displayed
      autoplay = true,
      continuityInterval,
      concurrencyInitCallFinished = true;

    let toggleCaption = () => {
      let textTracks = this.player.textTracks();

      for (let i = 0; i < textTracks.length; i++) {
        const textTracksType = textTracks[i].kind;
        if ((textTracksType === "subtitles" || textTracksType === "captions") && textTracks[i].language !== "") {
          if (textTracks[i].mode === "showing") {
            textTracks[i].mode = "disabled";
          } else {
            textTracks[i].mode = "showing";
          }
          break;
        }
      }
    };

    let captionIsAvailable = () => {
      let textTracks = this.player.textTracks();
      let ccIsOn = ccButtonEl_.className.includes("vjs-cc-on");

      for (let i = 0; i < textTracks.length; i++) {
        const textTracksType = textTracks[i].kind;
        if ((textTracksType === "subtitles" || textTracksType === "captions") && textTracks[i].language !== "") {
          if (textTracks[i].mode === "showing") {
            // Turn off the caption by default.
            textTracks[i].mode = "disabled";
          }
          ccButtonEl_.className = "vjs-cc-btn vjs-control vjs-button";

          return true;
        }
      }

      return false;
    };

    let showCCButton = () => {
      ccButtonEl_.style.display = "block";
    };

    let hideCCButton = () => {
      ccButtonEl_.style.display = "none";
    };

    let onCCButtonClick = () => {
      let ccIsOn = ccButtonEl_.className.includes("vjs-cc-on");
      if (ccIsOn) {
        ccButtonEl_.className = "vjs-cc-btn vjs-control vjs-button";
      } else {
        ccButtonEl_.className += " vjs-cc-on";
      }

      toggleCaption();
    };

    let createNextOverlay = () => {
      nextOverlayUpNextEl_.className = "next-overlay-upnext";
      nextOverlayUpNextEl_.innerHTML = "UP NEXT";
      nextOverlayCountDownEl_.className = "next-overlay-countdown";
      nextOverlaySeriesTitleEl_.className = "next-overlay-seriestitle ellipsis-overflow";
      nextOverlayEpisodeTitleEl_.className = "next-overlay-episodetitle ellipsis-overflow";
      nextOverlayInfoPanelEl_.className = "next-overlay-infopanel";
      nextOverlayReelzLogoEl_.className = "next-overlay-logo";
      nextOverlayThumbnailEl_.className = "next-overlay-thumbnail";
      nextOverlayEl_.className = "next-overlay";

      nextOverlayInfoPanelEl_.append(nextOverlayUpNextEl_);
      nextOverlayInfoPanelEl_.append(nextOverlayCountDownEl_);
      nextOverlayInfoPanelEl_.append(nextOverlaySeriesTitleEl_);
      nextOverlayInfoPanelEl_.append(nextOverlayEpisodeTitleEl_);
      nextOverlayEl_.append(nextOverlayThumbnailEl_);
      nextOverlayEl_.append(nextOverlayReelzLogoEl_);
      nextOverlayEl_.append(nextOverlayInfoPanelEl_);
      this.player.el_.append(nextOverlayEl_);

      nextOverlayEl_.addEventListener("click", onVideoEnded);
    };

    let updateNextOverlayInfo = (episodeTitle, seriesTitle, thumbnail) => {
      nextOverlaySeriesTitleEl_.innerHTML = seriesTitle;
      nextOverlayEpisodeTitleEl_.innerHTML = episodeTitle;
      nextOverlayThumbnailEl_.style.background = "url(" + thumbnail + ") no-repeat";
      nextOverlayThumbnailEl_.style.backgroundSize = "contain";
    };

    let showNextOverlay = () => {
      nextOverlayEl_.style.visibility = "visible";
    };

    let hideNextOverlay = () => {
      nextOverlayEl_.style.visibility = "hidden";
    };

    let nextOverlayIsShowing = () => {
      return nextOverlayEl_.style.visibility === "visible";
    };

    let onVideoEnded = () => {
      deleteContinuity();

      continuityInterval && clearInterval(continuityInterval);
      continuityInterval = null;

      if (nextEpisode && autoplay) {
        updateLocalStorage(StorageField.Autoplay, "true");
        // Redirect the user to the next episode
        window.location.href = window.location.origin + "/video/" + nextEpisode.id;
      }
    };

    let updateContinuity = () => {
      fetch(`${process.env.REACT_APP_MIDDLEWARE_URL}/client/event`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          "eventType": "update-video-position",
          "data": {
              "contentId": contentId,
              "position": Math.floor(this.player.currentTime() * 1000)
          }
        })
      });
    };

    let deleteContinuity = () => {
      fetch(`${process.env.REACT_APP_MIDDLEWARE_URL}/client/event`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          "eventType": "delete-video-position",
          "data": {
              "contentId": contentId
          }
        })
      });
    };

    controlBarEl_.removeChild(spacerEl_); // remove spacer first.

    controlBarEl_.removeChild(originalCCButtonEl_); // remove caption button.
    controlBarEl_.removeChild(volumePanelEl_);
    controlBarEl_.removeChild(durationEl_);
    controlBarEl_.removeChild(progressBarEl_);
    controlBarEl_.removeChild(currentTimeEl_);
    controlBarEl_.removeChild(timeDividerEl_);

    controlBarEl_.insertBefore(ccButtonEl_, controlBarEl_.childNodes[1]);
    controlBarEl_.insertBefore(volumePanelEl_, controlBarEl_.childNodes[1]);
    controlBarEl_.insertBefore(durationEl_, controlBarEl_.childNodes[1]);
    controlBarEl_.insertBefore(progressBarEl_, controlBarEl_.childNodes[1]);
    controlBarEl_.insertBefore(currentTimeEl_, controlBarEl_.childNodes[1]);

    progressHandleEl_.className = "vjs-progress-handle";
    volumeHandleEl_.className = "vjs-volume-handle";
    volumePanelTriangleEl_.className = "vjs-volume-triangle";
    ccButtonEl_.className = "vjs-cc-btn vjs-control vjs-button";

    seekBarEl_.append(progressHandleEl_);
    volumeControEl_.append(volumePanelTriangleEl_);
    volumeBarEl_.append(volumeHandleEl_);

    createNextOverlay();

    document.onclick = (e) => {
      // cancel autoplay if click outside of next overlay.
      if (nextOverlayIsShowing() &&
        e && e.target && e.target['className'] !== "next-overlay-upnext") {
        autoplay = false;
        hideNextOverlay();
      }
    };

    if (nextEpisode && nextEpisode.tile && nextEpisode.tile.image) {
      updateNextOverlayInfo(
        nextEpisode.name,
        header,
        nextEpisode.tile.image.url
      );
    }

    this.player.on("loadedmetadata", () => {
      if (position) {
        this.player.currentTime(position / 1000);
      }

      if (captionIsAvailable()) {
        showCCButton();
      } else {
        hideCCButton();
      }

      if (shouldAutoPlay === "true") {
        this.player.play();
        updateLocalStorage(StorageField.Autoplay, "false");
      }
    });

    this.player.on("timeupdate", () => {
      progressHandleEl_.style.right = tooltipEl_.style.right || '0px';

      let remainingTime = this.player.remainingTime();
      if (nextEpisode && autoplay && remainingTime <= this.COUNTDOWN_THRESHOLD) {
        showNextOverlay();
        nextOverlayCountDownEl_.innerHTML = "In " + Math.floor(remainingTime) + " sec.";
      } else {
        hideNextOverlay();
      }
    });
    this.player.on("ended", onVideoEnded);
    this.player.on("play", () => {
      continuityInterval = setInterval(function() {
        updateContinuity();
      }, this.CONTINUITY_FREQUENCY);

      // Don't init the concurrency call when the last call is not finished.
      if (!concurrencyInitCallFinished) {
        this.concurrencyManager.stop();
        return;
      }

      concurrencyInitCallFinished = false;
      this.concurrencyManager.init((response) => {
        if (this.player.paused()) {
          this.player.play();
        }
        concurrencyInitCallFinished = true;
      }, (response) => {
        let message = response.associatedAdvice && response.associatedAdvice[0] && response.associatedAdvice[0].message
        this.player.pause();
        this.concurrencyManager.stop();
        this.setState({
          errorMsg: message || "Something went wrong",
          error: true
        });
        concurrencyInitCallFinished = true;
      });
    });

    this.player.on("pause", () => {
      continuityInterval && clearInterval(continuityInterval);
      continuityInterval = null;
      updateContinuity();
      this.concurrencyManager.stop();
    });

    ccButtonEl_.addEventListener("click", onCCButtonClick);
  }).catch((err) => {
    this.setState({
      error: true,
      errorMsg: 'Player failed to load.'
    })
    //alert(JSON.stringify(err)); // iOS debug
    console.error(err);
  });
}

export default compose(withStore)(PlayerDetail)

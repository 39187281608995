import React from "react";
import { PlainInput as StudioPlainInput, PlainInputProps } from "@brightcove/studio-components";
import ControlledProps from "./ControlledProps";

export default class Input extends React.Component<PlainInputProps & ControlledProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || ""
    }
  }

  componentDidMount() {
    const { onChange, property } = this.props;
    const { value } = this.state;
    onChange && onChange(value, property);
  }

  onChange = (value) => {
    const { onChange, property } = this.props;
    this.setState({value})
    onChange && onChange(value, property);
  }

  render() {
    const { property, ...props } = this.props;
    return <StudioPlainInput {...props} value={this.state.value} onChange={this.onChange} />
  }
}

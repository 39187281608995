import React from "react";
import { SVGImage } from "../../assets/images";
import MultiLineDiv from "../MultiLineDiv/MultiLineDiv";
import { Responsive, Breakpoint } from "../Breakpoints";
import classnames from "classnames";
import ReactPlayerLoader from "../ReactPlayerLoader/ReactPlayerLoader";
import "./KeyArt.scss";
import { TweetButton, FacebookButtons } from "../SocialButtons/SocialButtons";

export default class KeyArt extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    }
  }

  onPlayerLoad = (success) => {
    let player = success.ref;
    let muteButton = document.createElement("div");
    let overlay = document.getElementsByClassName("overlay")[0];
    muteButton.className = "mute-btn";
    muteButton.addEventListener("click", () => {
      if (muteButton.classList.contains("muted")) {
        player.muted(false);
        muteButton.classList.remove("muted");
      } else {
        player.muted(true);
        muteButton.classList.add("muted");
      }
    });

    if (player.muted() && !muteButton.classList.contains("muted")) {
      muteButton.classList.add("muted");
    } else if (!player.muted() && muteButton.classList.contains("muted")) {
      muteButton.classList.remove("muted");
    }

    overlay.append(muteButton);

    player.one("play", () => {
      let checkOpacity = setInterval(() => {
        // @ts-ignore
        if (parseFloat(window.getComputedStyle(player.el_).opacity) > 0.2) {
          muteButton.style.display = "block";
          clearInterval(checkOpacity);
        }
      }, 100);
    });

    player.on("ended", () => {
      muteButton.style.display = "none";
    });

    player.play();
  }

  onToggleExpand = (expanded) => {
    this.setState({ expanded })
  };

  render() {
    const { header, subHeader, info, description, image, previewVideoId, color } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classnames(["KeyArt", { expanded }])} style={{ backgroundImage: `url(${image})` }}>
        <div className="image" />
        { previewVideoId &&
          <div className="preview">
            <ReactPlayerLoader
              videoId={previewVideoId}
              accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}
              onSuccess={this.onPlayerLoad}
              options={{ muted: true }}
            />
          </div>
        }
        <div className="overlay" style={{ backgroundImage: `linear-gradient(to right, ${color} 0%, rgba(0,0,0,0) 25%)`}}/>
        <div className="metadata">
          <div className="metadata-wrapper">
            <MultiLineDiv maxLines={2} className="header">{header}</MultiLineDiv>
            { subHeader && <div className="subheader">{subHeader}</div> }
            { info && <div className="info">{info}</div> }
            { description &&
              <Responsive>
              { breakpoint => {
                const showToggle = breakpoint != Breakpoint.Max;
                const maxLines = expanded ? undefined :
                  [Breakpoint.Desktop, Breakpoint.TabletWide].includes(breakpoint) ? 3 :
                  [Breakpoint.Tablet, Breakpoint.Mobile].includes(breakpoint) ? 2 :
                  undefined;

                  return (
                    <MultiLineDiv
                      maxLines={maxLines}
                      className="description"
                      onToggleExpand={showToggle && this.onToggleExpand}>
                      {description}
                    </MultiLineDiv>
                  )
              }}
              </Responsive>
            }
            <div className="social-buttons">
              <FacebookButtons url={window.location.href} />
              <TweetButton url={window.location.href} text={`Watch ${header} on Reelz!`} />
            </div>
          </div>
        </div>
      </div>
    )
  }
};

import React from "react";
import "./Loading.scss";
import { SVGImage } from "../../assets/images";
import classnames from "classnames";

const Loading: React.FunctionComponent<any> = (props) => {
  const loadingClassName = classnames("loading-indicator", {
    "loading-overlay": !props.inline
  }, props.className)
  return <div className={loadingClassName} style={props.style}><img src={SVGImage.Spinner} /></div>
};

export default Loading;

import React from "react";
import "./VideoMessageOverlay.scss";
import classnames from "classnames";
import Loading from "../Loading/Loading";
import { Button } from "@brightcove/studio-components";

const VideoMessageOverlay: React.FunctionComponent<any> = (props) => {
  const { authorizationStatus, error, backgroundImageUrl } = props;
  return <div className={classnames("VideoMessageOverlay", { fadeOut: authorizationStatus > 0 && !error })}>
    <div className="VideoMessageOverlay-poster"
      style={{
        backgroundImage: !error ? `url(${backgroundImageUrl})` : "none",
      }}
    ></div>
    <div className="VideoMessageOverlay-body">
      {
        returnLoadingScreen(props)
      }
    </div>
  </div>
};

function returnLoadingScreen(props) {
  const { authenticationStatus, error, onSignIn, errorMsg } = props;
  let component: any = <div className="loading"><Loading /></div>;
  // Adobe SDK has been loaded but the user is not yet signed in.
  if (authenticationStatus === 0) {
    component = <Button onClick={onSignIn}>Sign In To Watch</Button>;
  }
  if (error) {
    component = <div className="VideoMessageOverlay-message" dangerouslySetInnerHTML={{ __html: errorMsg}}></div>
  }
  return component
}

export default VideoMessageOverlay;

import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface AppLinkProps {
  load?: Boolean;
}

const AppLink: React.FunctionComponent<AppLinkProps & LinkProps> = ({ load, children, ...props }) => {
  return load ?
    <a href={props.to as string}>{children}</a> :
    <Link {...props}>{children}</Link>
};

export default AppLink;

class AuthHandler {
  userType: string
  constructor(userType: string, methods: object) {
    this.userType = userType;
    for (const method in methods) {
      this[method] = methods[method]
    }
  }
}

class AuthHandlerManager {
  private _authProviders: object
  constructor() {
    this._authProviders = {};
  }
  addProviderHandler = (provider: AuthHandler) => {
    this._authProviders[provider.userType] = provider;
  }
  CheckAuthorization = this.ProviderMethod("CheckAuthorization")

  private ProviderMethod(methodName) {
    return (userType, ...args) => {
      const provider = this._authProviders[userType];
      if (!provider) {
        const msg = `Provider with userType: "${userType}" does not exist`;
        console.log(msg);
        return;
      }
      if (!provider[methodName]) {
        const msg = `Provider Handler is missing the following Auth method: ${methodName}`
        console.log(msg);
        return;
      }
      return provider[methodName].apply(this, args)
    }
  }
}

// TODO
// Move out to separate file. Each new auth type handler should be in it's own location
const AdobeHandler = new AuthHandler("adobe", {
  CheckAuthorization: async (mvpd, refId) => {
    const resourceID = mvpd == "slingtv" ? sessionStorage.getItem("resourceId") : "reelz";
    return new Promise((resolve, reject) => {
      const handleGetAuthEvents = function (e) {
        const detail = (e as CustomEvent).detail;
        const eventType = detail.type;
        switch (eventType) {
          case "TOKEN_REQUEST_FAILED":
            console.log("TOKEN_REQUEST_FAILED", detail)
            let isSling
            if (refId) {
              isSling = adobeCheckAuthzForSling(mvpd, refId);
            }
            if (isSling) {
              break;
            }
            break;
          case "SET_TOKEN":
            break;
          default:
            return;
        }
        const data = {
          eventType,
          data: detail
        };
        window.removeEventListener("ADOBE_EVENT", handleGetAuthEvents);
        resolve(data);
      }
      //@ts-ignore
      window.addEventListener("ADOBE_EVENT", handleGetAuthEvents);
      //@ts-ignore
      adobeCheckAuth(resourceID, refId)
    })
  }
});

function adobeCheckAuth(resourceID, refId) {
  // Might eventually be used. Saving for reference
  const rating = "TV-14";
  const resource = refId === "liveTv" ? resourceID : `<?xml version="1.0"?><rss xmlns:media="http://search.yahoo.com/mrss/" version="2.0"><channel><title>${(resourceID || 'reelz')}</title><item><title></title><guid>${refId}</guid><media:rating scheme="urn:v-chip">${rating}</media:rating></item></channel></rss>`;
  //@ts-ignore
  window.getAccessEnabler().checkAuthorization(resource || 'reelz')
}

function adobeCheckAuthzForSling(mvpd, refId) {
  console.log("adobeCheckAuthzForSling", mvpd, refId)
  if (mvpd === "slingtv") {
    let resourceId: any = sessionStorage.getItem("resourceId") || "reelz";
    switch (resourceId) {
      case "reelz":
        resourceId = "BReelz";
        break;
      case "BReelz":
        resourceId = "OReelz";
        break;
      case "OReelz":
        resourceId = null;
        break;
    }
    console.log(mvpd, refId, resourceId)
    if (!resourceId) {
      sessionStorage.removeItem("resourceId");
      return false;
    } else {
      sessionStorage.setItem("resourceId", resourceId)
      adobeCheckAuth(resourceId, refId);
      return true;
    }
  } else {
    return false;
  }
}

const Manager = new AuthHandlerManager();

Manager.addProviderHandler(AdobeHandler)

export default { CheckAuthorization: Manager.CheckAuthorization };

/**

  Should accept a new AuthHandler object
AuthHandler object should contain a usertype
and the following methods:
CheckAuthorization


*/



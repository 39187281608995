import React from "react";
import { SVGImage } from "../../assets/images";
import "./GeoRestrictionPage.scss";

const GeoRestrictionPage: React.SFC<any> = () => {
  return (
    <div className="GeoRestrictionPage">
      <div className="Background">
        <div className="Logo"><img src={SVGImage.ReelzNowLogoReverse} /></div>
        <div className="Title">Sorry, REELZ Now is not available in your area</div>
        <div className="Subtitle">
          <p>
            Reelz is only available in the U.S, U.S territories, Puerto Rico, Guam and the U.S Virgin Islands.
          </p>
        </div>
      </div>
      <div className="Gradient" />
    </div>
  )
};

export default GeoRestrictionPage;

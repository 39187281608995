import {
  ENTITLEMENT_LOADED,
  SET_CONFIG,
  DISPLAY_PROVIDER_DIALOG,
  CREATE_IFRAME,
  SET_AUTHENTICATION_STATUS,
  SEND_TRACKING_DATA,
  SET_TOKEN,
  TOKEN_REQUEST_FAILED,
  PREAUTHORIZED_RESOURCES,
  SET_METADATA_STATUS,
  SELECTED_PROVIDER
} from './AdobeAuthActions';


// Handle all async things here

const AdobeAuth = store => next => action => {
  switch (action.type) {
    case ENTITLEMENT_LOADED : console.log(`Middleware: ${ENTITLEMENT_LOADED}`); return next(action); break;
    case SET_CONFIG : return next(displayActionData(action)); break;
    case DISPLAY_PROVIDER_DIALOG : next(displayActionData(action)); break;
    case CREATE_IFRAME : break;
    case SET_AUTHENTICATION_STATUS : return next(displayActionData(action)); break;
    case SEND_TRACKING_DATA : break;
    case SET_TOKEN : break;
    case TOKEN_REQUEST_FAILED : break;
    case PREAUTHORIZED_RESOURCES : break;
    case SET_METADATA_STATUS :
    case SELECTED_PROVIDER : return next(displayActionData(action)); break;
    default : return next(action);
  }
}

function displayActionData(action:any) {
  // console.log(`Middleware Action Type: ${action.type}`);
  // console.log(`Middleware Action Data: ${action.data && JSON.stringify(action.data)}`);
  return action;
}

export default AdobeAuth

import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../../store";
import { Image } from "../../../assets/images";
import { getDate, getCurrentTimeZoneTime, getSupportedTimeZone } from "../../../utils";
import moment from "moment-timezone";
import "./LiveMarquee.scss";

const MarqueeListingTile: React.SFC<any> = ({ className, label, time, header, subHeader, src, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <img src={src}/>
      <div className="listing-body">
          <div className="listing-body-label"><span>{label}</span><span>{time}</span></div>
          <div className="listing-body-header">{header}</div>
          { subHeader && <div className="listing-body-subheader">{subHeader}</div> }
      </div>
  </div>
  )
};

const MarqueeListing: React.SFC<any> = ({ label, time, media }) => (
  <div className="MarqueeListing">
    <MarqueeListingTile
      className="MarqueeListing-media"
      src={media.src}
      time={time}
      label={label}
      header={media.showName}
      subHeader={media.episodeName}/>
  </div>
);

const getImage = async (url) => {
  try {
    let result = await fetch(url, {method: 'HEAD', mode: 'no-cors'});
    if (result.ok) {
      return url;
    }
    const location = result.headers.get('Location')
    if (result.status === 302 && location) {
      return await getImage(location);
    }
  } catch {}
  return Image.DefaultLiveAsset;
}

const queueImage = async (titleCode, isPoster) => {
  return getImageUrl(titleCode, isPoster);
}

const getImageUrl = (titleCode, isPoster) => {
  let type = isPoster ? 'poster' : 'thumbnail';
  return `https://syndication.reelz.io/v1/syndication/accounts/${process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}/feeds/basic/media/ref:${titleCode}/redirect/images/${type}`;
}


const LiveMarquee: React.FunctionComponent<any> = ({ schedule, ...props }) => {
  let currentIndex;
  let currentTime = getCurrentTimeZoneTime();
  const utcOffset = currentTime.utcOffset();

  const [backgroundImage, setBackgroundImage] = useState('');
    let targetDay = currentTime;
    let nextDay = moment(targetDay).add(1, "days");
    let selectedDayData = schedule.filter(dataEntry => (
        getDate(dataEntry)!.isSame(targetDay, "day") ||
        getDate(dataEntry)!.isSame(nextDay, "day")
      )
    );
    
    selectedDayData= selectedDayData.sort(function (left, right) {
      return moment.utc(getDate(left)).diff(moment.utc(getDate(right)))
    });

  for(let i=selectedDayData.length-1; i>=0; i--) {
    let dataEntry= selectedDayData[i];
    let nextDataEntry= (i < (selectedDayData.length - 1)) && selectedDayData[i + 1];
    const startTime = getDate(dataEntry)!;
    const endTime = getDate(nextDataEntry) || startTime.add(1, "hours");
    if(currentTime.isBetween(
      startTime.utcOffset(utcOffset, true),
      endTime.utcOffset(utcOffset, true)
    )) {
      currentIndex = i;
      break;
    }
  }

  let marqueeListings;
  let marqueeLabels;

  if(typeof currentIndex !== "undefined") {
    marqueeListings = selectedDayData.slice(currentIndex, currentIndex + 3);
    marqueeLabels = ["LIVE NOW", "NEXT", "LATER"];
    if(marqueeListings[0] && !backgroundImage) {
      queueImage(marqueeListings[0].title_code, true)
        .then((src) => {
          setBackgroundImage(src);
        })
    }
  }
  else {
    marqueeListings = selectedDayData.slice(0, 2);
    marqueeLabels = ["NEXT", "LATER"];
  }


  return (
    <div className={"LiveMarquee" + (backgroundImage ? " has-live" : "")} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="LiveMarquee-wrapper">
        { marqueeListings.map((entry, index) => {
            let entryTime = getDate(entry);
            let image = getImageUrl(entry.title_code, false);

            return (
              <MarqueeListing
                key={index}
                label={marqueeLabels[index]}
                time={moment(entryTime).format("h:mm A")}
                media={{
                  src: image,
                  showName: entry.show_title,
                  episodeName: entry.program_name
                }}
              />
            )
          })
        }
      </div>
    </div>
  )
};

export default compose<any, any, any>(withRouter, withStore)(LiveMarquee);

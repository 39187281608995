import React from "react";
import Tile from "../Tile/Tile";
import _ from "lodash";
import "./Grid.scss";

const SortKeys = {
  alphabetical: "header",
  date_added: "dateAdded"
};

export default class Grid extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: props.sortOptions && props.sortOptions[0].method
    }
  }

  onSortChange = (sortBy) => {
    this.setState({ sortBy })
  }

  render() {
    const { tiles, header, sort } = this.props;
    const { sortBy } = this.state;

    return (
      <div className="Grid">
        <div className="Grid-header">{header}</div>
        { sort && React.cloneElement(sort, { onChange: this.onSortChange }) }
        <div className="Grid-contents">
          {_.sortBy(tiles, SortKeys[sortBy]).map((tile, index) => <Tile key={index} {...tile} />)}
        </div>
      </div>
    )
  }
};

import React from "react";
import "./BannerAd.scss";

const AdUnits = [
  {
    id: "div-gpt-ad-1554492529279-0",
    name: "/1001670/TVE_WebApp_320x50",
    height: 50,
    width: 320
  },
  {
    id: "div-gpt-ad-1554492628470-0",
    name: "/1001670/TVE_WebApp_468x60",
    height: 60,
    width: 468
  },
  {
    id: "div-gpt-ad-1554492659560-0",
    name: "/1001670/TVE_WebApp_728x90",
    height: 90,
    width: 728
  },
  {
    id: "div-gpt-ad-1554492681854-0",
    name: "/1001670/TVE_WebApp_970x90",
    height: 90,
    width: 970
  }
];

export default class BannerAd extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      ad: AdUnits[0]
    }
    this.initAds();
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowChange);
    window.addEventListener("orientationchange", this.onWindowChange);
    this.onWindowChange();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowChange);
    window.removeEventListener("orientationchange", this.onWindowChange);
  }

  componentDidUpdate() {
    let googletag = window["googletag"];
    let adId = this.state.ad.id;
    googletag.cmd.push(function() { googletag.display(adId); })
  }

  initAds = () => {
    let googletag = window["googletag"];
    for(let unit of AdUnits) {
      googletag.cmd.push(function() {
        googletag.defineSlot(unit.name, [unit.width, unit.height], unit.id).addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });
    }
  }

  onWindowChange = () => {
    let ad;
    for(let unit of AdUnits) {
      if(window.screen.width >= unit.width) {
        ad = unit;
      }
    }

    if(ad && ad.id != this.state.ad) {
      this.setState({ ad })
    }
  }

  render() {
    const { ad } = this.state;
    return (
      <div className="banner">
        <div id={ad.id} style={{height: `${ad.height}px`, width: `${ad.width}px`}} />
      </div>
    )
  }
};

import { createStore as createReduxStore, applyMiddleware } from "redux";
import { StoreState, GenericAction, StorageField, storageKey } from "./types";
import reducers, { initialState as defaultState, initLocalStorage, updateLocalStorage, clearLocalStorage, getLocalStorageItem } from "./reducers";
import AdobeAuth from './AdobeMiddleware';
import withStore from "./mappers";

export default function createStore(initialState) {
  let state = {...defaultState, ...initialState};
  initLocalStorage(state);
  return createReduxStore<StoreState, GenericAction, any, any>(reducers, state, applyMiddleware(AdobeAuth));
};

export { StorageField, storageKey, withStore, updateLocalStorage, clearLocalStorage, getLocalStorageItem };

import React from "react";
import { withRouter } from "react-router-dom";
import "./HomePage.scss";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import Fetch from "../../components/Fetch/Fetch";
import Loading from "../../components/Loading/Loading";
import ScreenParser from "../../parser/ScreenParser";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import Helmet from "react-helmet";
import ServiceMessages from "../../components/ServiceMessages/ServiceMessages";

const HomePage: React.FunctionComponent<any> = ({ store: { language } }) => {
  return (
    <Fetch 
      url={process.env.REACT_APP_HOMEPAGE_URL} 
      addMiddlewareHeaders 
      options={{ credentials: "include" }} 
      authzBeforeRequest
      >
      {(loading, errors, data) => {
        let screen = ScreenParser(data && data.templateScreen || {}, language);
        let serviceMessages = data && data.serviceMessages || [];

        return (
          errors ?
          <ErrorOverlay /> :
          <div className="HomePage" style={{backgroundColor: screen.backgroundColor}}>
            <Helmet>
              <title>{screen.title.en || screen.title}</title>
            </Helmet>
            { serviceMessages && <ServiceMessages messages={serviceMessages} /> }
            { loading && <Loading /> }
            { screen.components }
          </div>
        )
      }}
    </Fetch>
  )
};

export default compose(withRouter, withStore)(HomePage);

import React from "react";
import ReactDOM from "react-dom";
import "./ServiceMessages.scss";
import { Icon, ICONS } from "@brightcove/studio-components";
import _ from "lodash";

const appRoot = document.getElementById("root");
const StorageKey = "dismissed_messages";

export default class ServiceMessages extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      messages: this.filterByDismissed(props.messages)
    }
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(prevProps.messages, this.props.messages)) {
      this.setState({ messages: this.filterByDismissed(this.props.messages) })
    }
  }

  getDismissed = () => {
    return localStorage.getItem(StorageKey) && JSON.parse(localStorage.getItem(StorageKey)!) || [];
  }

  filterByDismissed = (messages) => {
    let dismissed = this.getDismissed();
    return messages.filter(message => !dismissed.includes(message.id));
  }

  saveDismissed = (id) => {
    // Ensures the number of dismissed messages is limited to the last 50 messages to prevent it from being unbounded
    let dismissed = _.takeRight(_.concat(this.getDismissed(), [id]), 50);
    localStorage.setItem(StorageKey, JSON.stringify(dismissed));
  }

  onCloseMessage = (id) => {
    this.saveDismissed(id);
    this.setState({
      messages: this.state.messages.filter(message => message.id != id)
    })
  }

  render() {
    const { messages } = this.state;

    return ReactDOM.createPortal(
      <div className="ServiceMessages">
      { messages.map((message, index) => {
          return (
            <div key={index} className="ServiceMessage">
              <span onClick={() => this.onCloseMessage(message.id)}>
                <Icon className="close" name={ICONS.CANCEL}/>
              </span>
              {message.message.en || message.message}
              </div>
          )
        })
      }
      </div>,
      document.getElementById("app-header") as HTMLElement
    );
  }
};

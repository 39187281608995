import config from "../config";

export interface StoreState {
  language: string;
  menuOpen: boolean;
  authenticationStatus: number;
  entitlementLoaded: boolean;
  displaySignIn: boolean;
  providers?: Array<any>;
  selectedProvider?: string;
  inMaintenance: boolean;
  maintenanceMessage?: string;
  pinDialogData?: any;
  userType?: string;
};

export interface GenericAction {
  type: string,
  data?: any
};

export enum StorageField {
  Language = "Language",
  LogoutFlag = "logout_flag",
  Autoplay = "Autoplay"
};

export function storageKey(field) {
  return `${config.localStoragePrefix}.${field}`
}

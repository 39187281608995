import React from "react"
import { flowRight as compose } from 'lodash';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom"
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { ADOBE_EVENT } from "./store/AdobeAuthActions";
import { initialize } from '@brightcove/studio-components'
import createStore, {withStore} from "./store";
import { Provider as ReduxProvider } from 'react-redux'
import HomePage from './routes/HomePage/HomePage'
import GeoRestrictionPage from './routes/GeoRestrictionPage/GeoRestrictionPage'
import InfoPage from './routes/InfoPage/InfoPage'
import ShowsPage from './routes/ShowsPage/ShowsPage'
import SchedulePage from './routes/SchedulePage/SchedulePage'
import SignInProviders from './components/SignInProviders/SignInProviders'
import SearchPage from "./routes/SearchPage/SearchPage";
import ShowPage from "./routes/ShowPage/ShowPage";
import VideoPage from "./routes/VideoPage/VideoPage";
import ActivatePage from "./routes/ActivatePage/ActivatePage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import MaintenanceOverlay from "./components/MaintenanceOverlay/MaintenanceOverlay";
import classnames from "classnames";
import LiveStreamPage from "./routes/LiveStreamPage/LiveStreamPage";

initialize();


const store = createStore({});

const AppWrapper: React.FunctionComponent<any> = ({ store, location }) => {
  const cleanedLocation =  (location ? location.pathname : "").replace("/", "")
  const className = classnames("app-layout", cleanedLocation);

  return (
    <div className={className}>
      {
        cleanedLocation !== "georestriction" ? <Header/> : null
      }
      <div className="app-body">
        {
          store.displaySignIn ?
            <SignInProviders providers={store.providers} selectedProvider={store.selectedProvider} pinDialogData={store.pinDialogData}/> :
          store.inMaintenance ?
            <MaintenanceOverlay message={store.maintenanceMessage} /> :
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/shows" exact component={ShowsPage} />
            <Route path="/schedule" exact component={SchedulePage} />
            <Route path="/info" exact component={InfoPage} />
            <Route path="/search" exact component={SearchPage} />
            <Route path="/show/:id" exact component={ShowPage} />
            <Route path="/video/:id" exact component={VideoPage} />
            <Route path="/activate" exact component={ActivatePage} />
            <Route path="/live" component={LiveStreamPage} />
            <Route path="/screen/:id" component={ShowPage} />
            <Route path="/georestriction" exact component={GeoRestrictionPage} />
          </Switch>
        }
      </div>
      {
        cleanedLocation !== "georestriction" ? <Footer /> : null
      }
    </div>
  );
};
const AppBody = compose(withRouter, withStore)(AppWrapper);

class App extends React.Component {
  componentDidMount() {
    //Handles callbacks invoked from adobe primetime iframe
    window.addEventListener(ADOBE_EVENT, function(e) {
      const detail = (e as CustomEvent).detail;
      const eventType = detail.type;
      store.dispatch({
        type: eventType,
        data: detail.data
      })
    });
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop>
            <ReduxProvider store={store}>
              <AppBody />
            </ReduxProvider>
          </ScrollToTop>
        </Router>
      </React.Fragment>
    )
  }
}

export default App

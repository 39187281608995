import React from "react";
import { Input } from "@brightcove/studio-components";
import { SVG } from "../../assets/images";
import classnames from "classnames";
import _ from "lodash";
import "./SearchInput.scss";

export default class SearchInput extends React.Component<any, any> {
  static defaultProps = {
    minCharacters: 3,
  }

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      text: this.defaultText(),
      error: false
    }
  }

  defaultText = () => {
    return `Enter a minimum of ${this.props.minCharacters} characters for results.`
  }

  errorText = () => {
    return `Sorry, there are no results for '${this.state.value}'.`;
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.error && this.props.error) {
      this.setState({ text: this.errorText() });
    }
    else if(prevProps.error && !this.props.error) {
      this.setState({ text: undefined });
    }
  }

  onChange = (value) => {
    const { minCharacters, onChange } = this.props;
    this.setState({ value, text: value.length >= minCharacters ? undefined : this.defaultText() });

    if(onChange && (value.length >= minCharacters || !value.length)) {
      let debouncedOnChange = _.debounce(onChange, 750);
      debouncedOnChange(value);
    }
  };

  render() {
    const { value, text } = this.state;
    const { error } = this.props;
    return (
      <div className="search-input">
        <div className="search-bar">
          <SVG.Search className="search-bar-icon"/>
          <Input 
           className="search-bar-field"
           onChange={this.onChange} 
           autoFocus 
           value={value}/>
        </div>
        <div className={classnames(["search-text", { error: error }])}>{text}&nbsp;</div>
      </div>
    )
  }
}

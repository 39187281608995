import React from "react";
import "./Expander.scss";
import { Icon, ICONS } from "@brightcove/studio-components";

const Expander: React.FunctionComponent<any> = ({ expanded, onClick }) => {
  return (
    expanded ?
      <div className="Expander" onClick={() => onClick(false)}><Icon name={ICONS.TRIANGLE_UP}/><span>Collapse</span></div> :
      <div className="Expander" onClick={() => onClick(true)}><Icon name={ICONS.TRIANGLE_DOWN}/><span>Expand</span></div>
  )
};

export default Expander;

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import Tile from '../Tile/Tile';
import { SVGImage } from "../../assets/images";
import "./Carousel.scss";

const CarouselArow: React.FunctionComponent<any> = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);

const Carousel: React.FunctionComponent<any> = ({ header, tiles }) => {
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: false,
    prevArrow: <CarouselArow><img src={SVGImage.ArrowLeft} /><img src={SVGImage.ArrowLeftHover} /><img src={SVGImage.ArrowLeftInactive} /></CarouselArow>,
    nextArrow: <CarouselArow><img src={SVGImage.ArrowRight} /><img src={SVGImage.ArrowRightHover} /><img src={SVGImage.ArrowRightInactive} /></CarouselArow>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          swipeToSlide: true,
          arrows: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          swipeToSlide: true,
          arrows: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          swipeToSlide: true,
          arrows: false,
          draggable: true
        }
      }
    ]
  }

  return (
    <div className="Carousel">
      <div className="Carousel-header">{header}</div>
      <Slider {...settings}>
        { tiles.map((tile, index) => <Tile key={index} carouselLength={tiles.length} {...tile} />) }
      </Slider>
    </div>
  )
};

export default Carousel;

import React from "react";
import { withRouter, Link } from "react-router-dom";
import "./ActivatePage.scss";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import { Input } from "../../components/ControlledComponents"
import { Button } from "@brightcove/studio-components";
import { SVGImage } from "../../assets/images";
import Fetch from "../../components/Fetch/Fetch"
import Loading from "../../components/Loading/Loading"
import classnames from "classnames";
/*
Flow reference
  loading providers
    is redirect from authenticate (check localstore)
      hide button
      show spinner
      check auth of regcode
      show success graphic or show error (delete localstore)
    is first time
      check if regcode is valid
      add values to localstore
      initiate authenticate process

*/

const fetchHandler = (url, success, error) => {
  fetch(url).then((response) => {
    if (response.status === 200) {
      success()
    } else {
      error();
    }
  })
}

class ActivatePage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = new URLSearchParams(url.search);
    const code = searchParams.get('code');
    this.state = {
      code: code || "",
      fromQR: code || false,
      // Generic loading state - prevents flash of content while the page determines whether or not it's a redirect
      loading: true,
      isRedirect: true,
      codeActivationSuccess: false,
      errorMessage: "",
      checkingCode: false
    }
  }

  componentDidMount() {
    const { registrationProvider, registrationCode } = this.getRegistrationDataFromStorage();
    if (registrationProvider && registrationCode) {
      this.setState({
        loading: false,
        isRedirect: true,
        code: registrationCode
      }, () => {
        this.validatePinRegistrationSuccess(registrationCode, () => {
          this.removeRegistrationDataFromStorage();
          this.setState({
            codeActivationSuccess: true,
          })
        }, () => {
          this.removeRegistrationDataFromStorage();
          this.setState({
            errorMessage: "Something went wrong during the activation process. Please try again later.",
            loading: false,
            isRedirect: false
          })
        })
      })
    } else {
      if (this.state.fromQR) {
        this.submitRegistrationCode()
      } else {
        // Load first visit screen with code input visible
        this.setState({
          loading: false,
          isRedirect: false
        })
      }
    }
  }
  checkRegistrationCode(code, callback, errorHandler) {
    this.setState({ checkingCode: true }, () => {
      const url = `${process.env.REACT_APP_ADOBE_AUTH_API}/reggie/v1/reelz/regcode/${code.toUpperCase()}?requestor=reelz`;
      fetchHandler(url, callback, errorHandler)
    })
  }
  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (!prevProps.providers && this.props.providers) {
      if (this.state.fromQR) {
        this.submitRegistrationCode();
        // setTimeout(this.submitRegistrationCode, 2500)
      }
    }
  }
  validatePinRegistrationSuccess(code, callback, errorHandler) {
    const url = `${process.env.REACT_APP_ADOBE_AUTH_API}/api/v1/checkauthn/${code.toUpperCase()}?requestor=reelz`;
    fetchHandler(url, callback, errorHandler)
  }
  getRegistrationDataFromStorage() {
    const registrationProvider = localStorage.getItem("registrationProvider");
    const registrationCode = localStorage.getItem("registrationCode");
    return {
      registrationProvider,
      registrationCode
    }
  }
  removeRegistrationDataFromStorage() {
    localStorage.removeItem("registrationProvider")
    localStorage.removeItem("registrationCode")
  }
  codeOnChange = (value) => {
    this.setState({ code: value });
  }
  submitRegistrationCode = (e?) => {
    if (e) {
      e.preventDefault();
    }
    const providers = this.props.providers;
    const pinDialogData = {
      isPIN: true,
      selectProviderFn: this.selectProviderFn
    }
    window["pinDialogData"] = pinDialogData;
    this.checkRegistrationCode(this.state.code, () => {
      this.setState({ loading: true },
        () => {
          // Using the same adobePrimetime event to trigger a dialog display. Passing in additional pinDialogData property in order to modify the experience
          // @ts-ignore
          dispatchAdobeEvent("DISPLAY_PROVIDER_DIALOG", { providers, pinDialogData })
        }
      )
    }, () => {
      this.setState({
        checkingCode: false,
        errorMessage: "Invalid code. Please check the code and try again."
      })
    })
  }
  selectProviderFn = (providerID) => {
    const url = `${process.env.REACT_APP_ADOBE_AUTH_API}/api/v1/authenticate?reg_code=${this.state.code.toUpperCase()}&mso_id=${providerID}&requestor_id=reelz&domain_name=reelz.com&noflash=true&no_iframe=true&redirect_url=${window.location.protocol.replace(':', '')}%3A%2F%2F${window.location.host}/activate`;
    localStorage.setItem("registrationProvider", providerID);
    localStorage.setItem("registrationCode", this.state.code.toUpperCase());
    window.location.replace(url)
  }
  render() {
    const { code, isRedirect, errorMessage, codeActivationSuccess, loading, checkingCode } = this.state;
    const activatePageClassName = classnames("activate-page", {
      "activate-page-success": codeActivationSuccess
    })
    return (
      <div className={activatePageClassName}>
        <Link to="/"><img src={SVGImage.ReelzNowLogoReverse} /></Link>
        <div>
          {
            codeActivationSuccess ?
              <div className="activate-success">
                <img src={SVGImage.SuccessIconSmall} />
                <div>SUCCESS</div>
              </div> :
              (
                <React.Fragment>
                  <h2>Activate your device</h2>
                  {
                    isRedirect ?
                      <p>Confirming your activation...</p> :
                      <p>Please enter your activation code</p>
                  }
                  <div className="activate-input">
                    <form className="activate-input-field" onSubmit={this.submitRegistrationCode}>
                      <Input autoFocus disabled={loading || isRedirect} value={code} onChange={this.codeOnChange} />
                      {
                        checkingCode || isRedirect || loading ?
                          <Loading inline={true} style={{ margin: '24px' }} /> :
                          null
                      }
                    </form>
                  </div>
                  {
                    !isRedirect ?
                      <Button text="Activate" style={{backgroundColor: '#DC0023'}} className="activate-page-button" onClick={this.submitRegistrationCode} /> :
                      null
                  }
                  <div className="activate-error">{errorMessage}</div>
                </React.Fragment>
              )
          }
        </div>
      </div>
    )
  }
};

const ActivatePageWithProviders = (props) => {
  return (
    <Fetch
      url={`${process.env.REACT_APP_ADOBE_AUTH_API}/api/v1/config/reelz.json`}
      method="GET"
    >
      {
        (loading, error, data) => {
          // Have to transform this because the provider list that we get from one Adobe
          // endpoint is not exactly like the one we get from the config endpoint
          const providers = data && data.requestor && data.requestor.mvpds.mvpd.map((p) => {
            return {
              ID: p.id.value,
              displayName: p.displayName.value,
              logoURL: p.logoUrl.value
            }
          })
          return <ActivatePage providers={providers} store={props.store} />
        }
      }
    </Fetch>
  )
}

export default compose(withRouter, withStore)(ActivatePageWithProviders);

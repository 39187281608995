import React from "react";
import Expander from "../Expander/Expander";

// DOM sizes don't seem to change immediately after certain events (ie. mounting, resizing) so this waits before
// attempting to perform any operations
const waitTimeMilliseconds = 550;

export default class MultiLineDiv extends React.Component<any, any> {
  container;
  el;

  static defaultProps = {
    children: ""
  }

  constructor(props) {
    super(props);
    this.state = {
      text: props.children,
      expanded: false
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowChange);
    window.addEventListener("orientationchange", this.onWindowChange);

    setTimeout(() => {
      this.calculateText(() => {
        this.props.onSetup && this.props.onSetup();
      });
    }, waitTimeMilliseconds);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowChange);
    window.removeEventListener("orientationchange", this.onWindowChange);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.maxLines != this.props.maxLines ||
      this.getMaxHeight(prevProps.maxHeight) != this.getMaxHeight(this.props.maxHeight) ||
      prevState.expanded != this.state.expanded) {
      this.calculateText();
    }
  }

  onWindowChange = () => {
    this.el.textContent = this.props.children;
    this.calculateText();
  }

  getLineHeight = () => {
    let temp = document.createElement(this.container.nodeName);
    let containerStyle = window.getComputedStyle(this.container);
    temp.style.fontFamily = containerStyle.fontFamily;
    temp.style.fontSize = containerStyle.fontSize;
    temp.style.lineHeight = containerStyle.lineHeight;
    temp.style.margin = 0;
    temp.style.padding = 0;
    temp.style.whitespace = "nowrap";
    temp.innerHTML = "Test";
    this.container.parentNode.appendChild(temp);
    let height = temp.clientHeight;
    temp.parentNode.removeChild(temp);
    return height;
  }

  getMaxHeight = (maxHeightProp) => {
    let maxHeightCSS = window.getComputedStyle(this.container).maxHeight;
    return maxHeightCSS && Number(maxHeightCSS.replace(/px|vh|vw/g, "")) || maxHeightProp;
  }

  calculateText = (callback?) => {
    let maxLinesTruncation = this.truncateToMaxLines();
    let maxHeightTruncation = this.truncateToMaxHeight();

    let text = maxLinesTruncation.length < maxHeightTruncation.length ?
      maxLinesTruncation : maxHeightTruncation;

    if(text != this.state.text) {
      this.setState({ text }, callback);
    }
  }

  truncateToMaxLines = () => {
    const { maxLines, children } = this.props;
    const { expanded } = this.state;
    let lineHeight = this.getLineHeight();
    let numLines = (this.el.getBoundingClientRect().height / lineHeight);
    let words = (children as string || "").split(" ");

    if(!maxLines || expanded)
      return children as string;

    while(numLines > maxLines) {
      words.pop();
      words[words.length -1] += "...";
      this.el.textContent = words.join(" ");
      numLines = Math.round(this.el.getBoundingClientRect().height / lineHeight);
    }
    return words.join(" ");
  }

  truncateToMaxHeight = () => {
    const { children, maxHeight: maxHeightProp } = this.props;
    const { expanded } = this.state;
    let maxHeight = this.getMaxHeight(maxHeightProp);
    let words = (children as string || "").split(" ");
    let height = this.el.getBoundingClientRect().height;

    if(!maxHeight || expanded)
      return children as string;

    while(height > maxHeight) {
      words.pop();
      words[words.length -1] += "...";
      this.el.textContent = words.join(" ");
      height = this.el.getBoundingClientRect().height;
    }
    return words.join(" ");
  }

  toggleExpanded = (expanded) => {
    const { onToggleExpand } = this.props;
    this.setState({ expanded })
    onToggleExpand && onToggleExpand(expanded);
  };

  render() {
    const { children, maxLines, onToggleExpand, onSetup, ...props } = this.props;
    const { text, expanded } = this.state;
    return (
      <React.Fragment>
        <div ref={(node) => this.container = node} {...props}>
          <span ref={(node) => this.el = node}>{text}</span>
        </div>
        { onToggleExpand && !(!expanded && text == children) &&
          <Expander expanded={expanded} onClick={this.toggleExpanded} />
        }
      </React.Fragment>
    )
  }
};

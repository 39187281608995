import { changeLanguage, changeCatalog, clearStore, toggleMenu, hideSignIn, selectProvider, handleLogout, toggleMaintenance } from "./actions";

import { connect } from 'react-redux';

const storePropsMapper = (state) => {
  return { store: state };
};

const storeActionsMapper = (dispatch) => {
  return {
    changeLanguage: (language) => dispatch(changeLanguage(language)),
    changeCatalog: (catalog, callback) => dispatch(changeCatalog(catalog, callback)),
    clearStore: () => dispatch(clearStore()),
    toggleMenu: () => dispatch(toggleMenu()),
    hideSignIn: () => dispatch(hideSignIn()),
    selectProvider: (provider) => dispatch(selectProvider(provider)),
    handleLogout: () => dispatch(handleLogout()),
    toggleMaintenance: (isActive, message) => dispatch(toggleMaintenance(isActive, message))
    // setAuthenticationStatus: ({isAuthenticated, errorCode}) => dispatch(setAuthenticationStatus({isAuthenticated, errorCode}))
  };
};

const withStore = connect(storePropsMapper, storeActionsMapper);

export default withStore;

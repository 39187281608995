import React from "react";
import "./ErrorOverlay.scss";

const ErrorOverlay: React.FunctionComponent<any> = () => {
    return (
      <div className="ErrorOverlay">
        <div className="header">Looks like something went wrong!</div>
        <div className="message">We track these errors automatically, but if the problem persists, feel free to contact us. In the meantime, try refreshing.</div>
      </div>
    )
}

export default ErrorOverlay;

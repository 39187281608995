import React from "react";
import { Select } from "../ControlledComponents";
import classnames from "classnames";
import "./Sort.scss";

const Sort: React.FunctionComponent<any> = ({ className, header, options, onChange }) => {
  return (
    <div className={classnames(["Sort", className])}>
      <span>{header}</span>
      <Select value={options[0].value} options={options} onChange={onChange}/>
    </div>
  )
};

export default Sort;

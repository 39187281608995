import React from "react";
import { withRouter, Link, NavLink } from "react-router-dom";
import "./Header.scss";
import { flowRight as compose } from 'lodash';
import { withStore, StorageField, getLocalStorageItem, updateLocalStorage } from "../../store";
import { SVGImage } from "../../assets/images";
import classnames from "classnames";
import { getProviderInfo } from "../../utils";

class UserMenu extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      providerUrl: "",
      providerLogo: ""
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { provider } = nextProps;

    if (provider) {
      let providers = JSON.parse(localStorage.getItem("providers") || "[]");
      let providerInfo = (providers.filter(p => p.ID == provider.MVPD)[0] || {});
      let providerExtraInfo = await getProviderInfo(providerInfo.ID);
      this.setState({
        providerUrl: providerExtraInfo.url,
        providerLogo: "https://devices.reelznow.com/prod/w300/mvpds/" + providerInfo.ID.toLowerCase() + ".png" //providerExtraInfo.logo || providerInfo.logoURL
      });
    }
  }

  render() {
    const { authenticationStatus, provider, onSignIn, onSignOut } = this.props;

    if (authenticationStatus && !provider) {
      window["getAccessEnabler"]().getSelectedProvider();
    }

    return (
      <div className="Header-user">
        {
          authenticationStatus && !provider ?
            null :
            authenticationStatus && provider ?
              <React.Fragment>
                <div onClick={onSignOut}><span>SIGN OUT</span></div>
                <a href={this.state.providerUrl} target="_blank"><img src={this.state.providerLogo} /></a>
              </React.Fragment> :
                <div onClick={onSignIn}><span>SIGN IN</span></div>
        }
      </div>
    )
  }
}

class Header extends React.Component<any, any> {
  menuEl;

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "/"
    }
  }

  componentDidMount() {
    this.getTab();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname != this.props.location.pathname) {
      if (this.props.store && this.props.store.displaySignIn) {
        this.props.hideSignIn();
      }

      this.getTab()
      this.closeMobileMenu();
    }

    if (this.props.store.authenticationStatus == 0 && getLocalStorageItem(StorageField.LogoutFlag) == "1") {
      updateLocalStorage(StorageField.LogoutFlag, undefined);
      if(this.props.location.pathname != "/")
        this.props.history.push("/");
    }
  }

  closeMobileMenu = () => {
    const { toggleMenu, store: { menuOpen } } = this.props;
    const menuVisible = this.menuEl && window.getComputedStyle(this.menuEl).display != "none";
    if(menuVisible && menuOpen) {
      toggleMenu();
    }
  }

  getTab = () => {
    let tabIds = ["/", "/shows", "/live", "/schedule", "/info"];
    let path = (/\/[a-zA-Z]*/g.exec(this.props.location.pathname) || [])[0];
    let selectedTab = "/";

    for (let id of tabIds) {
      if (path == id)
        selectedTab = id;
    }
    if (this.state.selectedTab != selectedTab) {
      this.setState({ selectedTab });
    }
  }

  onSelect = (id) => {
    const { history } = this.props;
    this.setState({
      selectedTab: id
    });
    history.push(id);
  }

  onSignIn = () => {
    window["getAccessEnabler"]().getAuthentication();
  };

  onSignOut = () => {
    this.props.handleLogout();
  };

  render() {
    const { toggleMenu, store: { menuOpen, authenticationStatus, selectedProvider, inMaintenance } } = this.props;
    return (
      <div className="Header">

        <div className="nav">
          <div className="menu" onClick={toggleMenu} ref={node => this.menuEl = node}><img src={SVGImage.Menu} /></div>
          <div className="nav-logo"><Link to="/"><img src={SVGImage.ReelzNowLogoReverse} /></Link></div>
          {!inMaintenance &&
            <React.Fragment>
              <div className="nav-search mobile"><Link to="/search"><img src={SVGImage.Search} className="icon" /></Link></div>
              <div className="nav-divider" />
              <div className={classnames("nav-menu_primary", { menu_closed: !menuOpen })}>
                <div><NavLink to='/' exact>HOME</NavLink></div>
                <div><NavLink to='/shows'>SHOWS</NavLink></div>
                <div><NavLink to='/live'>LIVE</NavLink></div>
                <div><NavLink to='/schedule'>ON REELZ TV</NavLink></div>
                <div><NavLink to='/info'>INFO</NavLink></div>
                <div className="nav-divider mobile"></div>
                {
                  (authenticationStatus > -1) ?
                    authenticationStatus && selectedProvider ?
                      <div className="mobile mobile_link" onClick={this.onSignOut}>SIGN OUT</div> :
                      <div className="mobile mobile_link" onClick={this.onSignIn}>SIGN IN</div>
                    : null
                }
              </div>

              <div className="nav-menu_secondary"><div className="nav-search"><Link to="/search"><img src={SVGImage.Search} className="icon" /></Link></div>
                {(authenticationStatus > -1) &&
                  <UserMenu authenticationStatus={authenticationStatus} provider={selectedProvider} onSignIn={this.onSignIn} onSignOut={this.onSignOut} />
                }
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    )
  }
};

export default compose(withRouter, withStore)(Header);

import React from "react";
import LiveMarquee from "./LiveMarquee/LiveMarquee";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import "./LiveSlide.scss";
import { Link } from "react-router-dom";
import Fetch from "../Fetch/Fetch";
import { getSupportedTimeZone, adjustForTimezone } from "../../utils";
import { SVGImage } from "../../assets/images";

class LiveSlide extends React.Component<any, any> {
  onSignIn = () => {
    const { store: { authenticationStatus } } = this.props;
    
    if (!(authenticationStatus && authenticationStatus > 0)) {
       window["getAccessEnabler"]().getAuthentication();
    }
  }
  
  render() {
    const { store: { authenticationStatus } } = this.props;
    let { url, image } = this.props; 
    let watchImage = SVGImage.WatchLiveSignIn;
    image = 'https://devices.reelznow.com/assets/live_arrowflare.jpg';
    
    if (authenticationStatus && authenticationStatus > 0) {
      url = this.props.url;
      watchImage = SVGImage.WatchLive;
    } else {
      url = "/";
      watchImage = SVGImage.WatchLiveSignIn;
    }
    
    return (
      <Link to={url} onClick={this.onSignIn}>
        <div className="LiveSlide">
          <div className="image" style={{ backgroundImage: `url(${image})` }}>
          </div>
          <div className="metadata">
            <Fetch url={process.env.REACT_APP_SCHEDULE_URL} addMiddlewareHeaders>
              {(loading, errors, data) => {
                const schedule = data && data.schedule && adjustForTimezone(data.schedule, getSupportedTimeZone()) || [];
                return <LiveMarquee schedule={schedule}/>
              }}
            </Fetch>
          </div>
        </div>
      </Link>
    )
  }
}

export default compose(withStore)(LiveSlide);

import React from "react";
import "./Footer.scss";
import Fetch from "../Fetch/Fetch";
import { SVGImage } from "../../assets/images";
import BannerAd from "../BannerAd/BannerAd";
import { Link } from "react-router-dom";


import packageJSON from '../../../package.json';
const appVersion = packageJSON.version

const QuickLinkItem: React.FunctionComponent<any> = ({ url, icon }) => {
  return <li><a href={url} target="_blank"><img src={icon} className="icon" /></a></li>
}

const Footer: React.FunctionComponent<any> = ({ }) => {
  return (
    <div className="Footer">
      <BannerAd />
      <div className="Footer-quicklinks">
          <div className="back-to-site"><img className="arrow" src={SVGImage.ArrowLeftSmall} /><a href="https://reelz.com">Back to REELZ.com</a></div>
          <ul className="Footer-quicklinks-social">
            <QuickLinkItem url="https://www.facebook.com/ReelzChannel" icon={SVGImage.Facebook} />
            <QuickLinkItem url="https://twitter.com/reelzchannel" icon={SVGImage.Twitter} />
            <QuickLinkItem url="https://www.instagram.com/reelzchannel" icon={SVGImage.Instagram} />
            <QuickLinkItem url="https://www.youtube.com/channel/UCXMz-cF-VxEUK4VohKk9hAA" icon={SVGImage.Youtube} />
          </ul>
          <ul className="Footer-quicklinks-apps">
            <li>Download the App:</li>
            <QuickLinkItem url="https://apps.apple.com/us/app/reelz-now/id955983276?ls=1" icon={SVGImage.Apple} />
            <QuickLinkItem url="https://play.google.com/store/apps/details?id=com.reelznow" icon={SVGImage.Google} />
            <QuickLinkItem url="https://www.amazon.com/Brightcove-REELZ-Now/dp/B07QMWV3L7" icon={SVGImage.Amazon} />
            <QuickLinkItem url="https://channelstore.roku.com/details/293568/reelz-now" icon={SVGImage.Roku} />
          </ul>
      </div>
      <div className="Footer-info">
        <div className="Footer-info-wrapper">
          <ul>
            <li><Link to="/info#about">About REELZ NOW</Link></li><div className="divider">|</div>
            <li><Link to="/info#privacy">Privacy</Link></li><div className="divider">|</div>
            <li><Link to="/info#terms">Terms</Link></li><div className="divider">|</div>
            <li><Link to="/info#legal">Legal Notices</Link></li>
          </ul>
          <div className="compliance-message">
            <Fetch url={process.env.REACT_APP_COMPLIANCE_URL}>
              {(loading, errors, data) => {
                return data && data.configurableString && data.configurableString.value || null;
              }}
            </Fetch>
          </div>
          <div style={{paddingTop:"1rem",fontSize:"0.5rem"}}>
            Version: {appVersion}
          </div>
        </div>
        <div className="store-downloads">
          <a target="_blank" href="https://apps.apple.com/us/app/reelz-now/id955983276?ls=1"><img src={SVGImage.DownloadApple} /></a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.reelznow"><img src={SVGImage.DownloadGoogle} /></a>
        </div>
      </div>
    </div>
  )
}

export default Footer;

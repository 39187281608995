import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Tile.scss";
import AppLink from "../AppLink/AppLink";
import { formatDuration } from "../../utils";
import { SVGImage } from "../../assets/images";

class Tile extends React.Component<any, any> {
  container;
  
  onMouseEnter = () => {
    const { carouselLength } = this.props;
    
    // Don't make it absolute when carousel only have 1 item
    if (carouselLength > 1) {
      let width = this.container.clientWidth;
      let height = this.container.clientHeight;
      this.container.style.width = width + "px";
      this.container.style.height = height + "px";
      this.container.style.position = "absolute";  
    }
    this.container.className += " Tile-hover";
  }
  
  onMouseLeave = () => {
    const { carouselLength } = this.props;
    
    if (carouselLength > 1) {
      this.container.style.position = "relative";
      this.container.style.width = "auto";
      this.container.style.height = "auto";
    }
    this.container.className = "Tile";
  }
  
  render() {
    const { header, subHeader, image, url, badge, position, duration, showDuration } = this.props;
    
    return (
      <AppLink load to={url}>
        <div className="Tile" ref={(node) => this.container = node} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <div className="Tile-badge">{badge  && <img src={SVGImage.NewBadge} /> }</div>
          <div className="Tile-image" style={{ backgroundImage: `url(${image})` }}>
            { (position && duration) &&
              <div className="Tile-progress">
                <div className="Tile-progress-bar" style={{ width: `${position/duration*100}%` }}/>
              </div>
            }
          </div>
          <div className="Tile-body">
            <div className="Tile-body-header">{header}</div>
            <div className="Tile-body-metadata">
              { subHeader  && <div className="Tile-body-subheader">{subHeader}</div> }
              { (showDuration && duration) && <div className="Tile-body-duration">{formatDuration(duration)}</div> }
            </div>
          </div>
        </div>
      </AppLink>
    )
  }
}

export default Tile;

import React from "react";

class TweetButton extends React.Component<any, any> {
  componentDidMount() {
    setTimeout(() => {
      window["twttr"] && window["twttr"].widgets && window["twttr"].widgets.load();
    }, 0)
  }

  render() {
    const { url, text } = this.props;
    return (
      <a
        target="_blank"
        className="twitter-share-button"
        href="https://twitter.com/intent/tweet"
        data-text={text}
        data-url={url}
        data-hashtags="reelz,episode"
        data-via="reelzchannel">
        Tweet
      </a>
    )
  }
}

class FacebookButtons extends React.Component<any, any> {
  componentDidMount() {
    setTimeout(() => {
      const script = document.createElement("script");
      script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.3&appId=168226830260104&autoLogAppEvents=1";
      script.defer = true;
      script.async = true;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
    }, 0)
  }

  render() {
    const { url } = this.props;
    return (
      <div
        className="fb-like"
        data-href={url}
        data-width=""
        data-layout="button"
        data-action="like"
        data-size="small"
        data-show-faces="false"
        data-share="true">
      </div>
    )
  }
}

export { TweetButton, FacebookButtons };

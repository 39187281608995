import moment from "moment-timezone";

export const timeZones = [
  { label: "EST", value: "EST", offset: 0 },
  { label: "CST", value: "CST", offset: 1 },
  { label: "MST", value: "MST", offset: 2 },
  { label: "PST", value: "PST", offset: 3 }
];

export const timeZoneCodes = {
  EST: "America/New_York",
  CST: "America/Chicago",
  MST: "America/Denver",
  PST: "America/Los_Angeles"
};

export const getSupportedTimeZone = () => {
  let timeZone = "EST";
  moment.tz.setDefault(moment.tz.guess());
  for(let tz of timeZones) {
    if((moment as any).defaultZone.abbrs.includes(tz.value)) {
      timeZone = tz.value;
      break;
    }
  }
  return timeZone;
}

export const getDate = (dataEntry) => {
  if(!(dataEntry.air_date && dataEntry.air_time))
    return undefined;
  return moment(`${dataEntry.air_date}T${dataEntry.air_time}`, "YYYY-MM-DDTHH:mm");
}

export const adjustForTimezone = (data, timeZone) => {
  if(!data) return [];

  let timeZoneOffset = timeZones.filter(tz => tz.value == timeZone)[0].offset;

  return data.map(dataEntry => {
    let date = getDate(dataEntry)!;
    let adjustedDate = date.subtract(timeZoneOffset, "hours");
    return { 
      ...dataEntry,
      air_date: adjustedDate.format("YYYY-MM-DD"),
      air_time: adjustedDate.format("HH:mm")
    }
  });
}

export const getCurrentTimeZoneTime = (timeZone?) => {
  return moment.tz(timeZoneCodes[timeZone || getSupportedTimeZone()]);
}

export const getProviderInfo = (id) => {
  //@ts-ignore
  return fetch(process.env.REACT_APP_PROVIDER_INFO_URL)
  .then((response) => response.json())
  .then((responseJSON) => {
    let providersInfo = responseJSON.providers || [];
    let blacklist = responseJSON.blacklist || [];
    let currentBlacklist = localStorage.getItem('blacklist') || "[]";
    let providers = {};
    let providerImageURL = process.env.REACT_APP_PROVIDER_IMAGE_URL;

    for(let i = 0; i < providersInfo.length; i ++) {
      if (blacklist.indexOf(providersInfo[i].id) > -1) continue;
      providers[providersInfo[i].id] = {
        //@ts-ignore
        logo: providerImageURL.replace("{width}", 180).replace("{provider}", providersInfo[i].id.toLowerCase()),
        url: providersInfo[i].site_url,
        featured: providersInfo[i].featured
      }
    }

    // filter all the providers on the list here if the blacklist needs an update
    if (currentBlacklist !== JSON.stringify(blacklist)) {
      let unfilteredProviders = localStorage.getItem('providers') || "[]";
      unfilteredProviders = JSON.parse(unfilteredProviders);
      localStorage.setItem("blacklist", JSON.stringify(blacklist));
      // @ts-ignore
      let filteredProviders = unfilteredProviders.filter((provider) => {
        return blacklist.indexOf(provider.ID) === -1
      });
      // dispatch adobe event to update the providers.
      localStorage.setItem('providers', JSON.stringify(filteredProviders));
      window.dispatchEvent(new CustomEvent('ADOBE_EVENT', {
        detail: {
          type: "DISPLAY_PROVIDER_DIALOG",
          data: {
            providers: filteredProviders
          }
        }
      }));
    }

    if (!id) { // return featured providers info if no id specified.
      const filteredProviders = {};
      Object.keys(providers).map((providerKey) => {
        const provider = providers[providerKey];
        if (provider.featured) filteredProviders[providerKey] = provider
      });
      return filteredProviders;
    }

    return providers[id] || {};
  });
}

export const formatDuration = (milliseconds) => {
  let totalSeconds = milliseconds / 1000;
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = Math.ceil(totalSeconds - (hours * 3600) - (minutes * 60));

  if(seconds > 0 && seconds <= 60) {
    minutes += 1;
    seconds = 0;
  }

  let duration = "";
  if(hours) duration += `${hours}hr `;
  if(minutes) duration += `${minutes}min`

  return duration;
}

export const getCookie = (name) => {
  let value = "; " + document.cookie;
  let parts = value.split("; " + name + "=");

  //@ts-ignore
  if (parts.length == 2) return parts.pop().split(";").shift();
}

export const ADOBE_EVENT = "ADOBE_EVENT";

export const ENTITLEMENT_LOADED = "ENTITLEMENT_LOADED";
export const SET_CONFIG = "SET_CONFIG";
export const DISPLAY_PROVIDER_DIALOG = "DISPLAY_PROVIDER_DIALOG";
export const CREATE_IFRAME = "CREATE_IFRAME";
export const SET_AUTHENTICATION_STATUS = "SET_AUTHENTICATION_STATUS";
export const SEND_TRACKING_DATA = "SEND_TRACKING_DATA";
export const SET_TOKEN = "SET_TOKEN";
export const TOKEN_REQUEST_FAILED = "TOKEN_REQUEST_FAILED";
export const PREAUTHORIZED_RESOURCES = "PREAUTHORIZED_RESOURCES";
export const SET_METADATA_STATUS = "SET_METADATA_STATUS";
export const SELECTED_PROVIDER = "SELECTED_PROVIDER";


import React from "react";
import MediaQuery from "react-responsive";
import _ from "lodash";

const Desktop: React.FunctionComponent = ({ children }) => {
  return (
    <MediaQuery minWidth={1025}>
      {children}
    </MediaQuery>
  )
};

const TabletWide = ({ children }) => {
  return (
    <MediaQuery minWidth={769} maxWidth={1024}>
      {children}
    </MediaQuery>
  )
};

const Tablet = ({ children }) => {
  return (
    <MediaQuery minWidth={601} maxWidth={768}>
      {children}
    </MediaQuery>
  )
};

const Mobile = ({ children }) => {
  return (
    <MediaQuery maxWidth={600}>
      {children}
    </MediaQuery>
  )
};

enum Breakpoint {
  Max,
  Desktop,
  TabletWide,
  Tablet,
  Mobile
};

const Breakpoints = {
  Max: { min: 1441 },
  Desktop: { min: 1025, max: 1440 },
  TabletWide: { min: 769, max: 1025},
  Tablet: { min: 601, max: 769},
  Mobile: {min: 0, max: 601}
};

interface ResponsiveProps {
  children: (breakpoint: Breakpoint, width?: number) => (React.ReactNode | null | undefined);
  reportAll?: boolean;
};

class Responsive extends React.Component<ResponsiveProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      breakpoint: this.getBreakpoint()
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowChange);
    window.addEventListener("orientationchange", this.onWindowChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowChange);
    window.removeEventListener("orientationchange", this.onWindowChange);
  }

  onWindowChange = () => {
    const { breakpoint } = this.state;
    const { reportAll } = this.props;

    let currentBreakpoint = this.getBreakpoint();
    if(breakpoint != currentBreakpoint) {
      this.setState({ breakpoint: currentBreakpoint });
    }
    else if(reportAll) {
      this.forceUpdate();
    }
  };

  getBreakpoint = () => {
    let width = window.screen.width;

    if(_.inRange(width, Breakpoints.Max.min, Number.POSITIVE_INFINITY)) {
      return Breakpoint.Max;
    }
    if(_.inRange(width, Breakpoints.Desktop.min, Breakpoints.Desktop.max)) {
      return Breakpoint.Desktop;
    }
    else if(_.inRange(width, Breakpoints.TabletWide.min, Breakpoints.TabletWide.max)) {
      return Breakpoint.TabletWide;
    }
    else if(_.inRange(width, Breakpoints.Tablet.min, Breakpoints.Tablet.max)) {
      return Breakpoint.Tablet;
    }
    else if(_.inRange(width, Breakpoints.Mobile.min, Breakpoints.Mobile.max)) {
      return Breakpoint.Mobile;
    }
  }

  render() {
    const { children } = this.props;
    const { breakpoint } = this.state;
    return children(breakpoint, window.screen.width);
  }
};

export { Desktop, TabletWide, Tablet, Mobile, Breakpoint, Responsive };

import ReelzNowLogo from "./icons/reelz-now-logo.svg";
import ReelzNowLogoReverse from "./icons/reelz-now-sm-reverse.svg";
import Facebook from "./icons/facebook.svg";
import Twitter from "./icons/twitter.svg";
import Instagram from "./icons/instagram.svg";
import Youtube from "./icons/youtube.svg";
import Amazon from "./icons/amazon.svg";
import Apple from "./icons/apple.svg";
import Google from "./icons/google.svg";
import Roku from "./icons/roku.svg";
import DownloadApple from "./icons/download-apple.svg";
import DownloadGoogle from "./icons/download-google.svg";

import Menu from "./icons/menu.svg";
import Search from "./icons/search-lg.svg";
import { ReactComponent as SearchComponent } from "./icons/search-lg.svg";
import ArrowLeftSmall from "./icons/arrow-left-sm.svg";

import PreviousArrow from "./icons/slider-arrow-left.svg"
import PreviousArrowHover from "./icons/slider-arrow-left-hover.svg"
import NextArrow from "./icons/slider-arrow-right.svg"
import NextArrowHover from "./icons/slider-arrow-right-hover.svg"
import CarouselDot from "./icons/pag-circle.svg"
import CarouselDotActive from "./icons/pag-circle-active.svg"

import ArrowLeft from "./icons/arrow-left.svg"
import ArrowLeftInactive from "./icons/arrow-left-inactive.svg"
import ArrowLeftHover from "./icons/arrow-left-hover.svg"
import ArrowRight from "./icons/arrow-right.svg"
import ArrowRightInactive from "./icons/arrow-right-inactive.svg"
import ArrowRightHover from "./icons/arrow-right-hover.svg"
import Back from "./icons/back.svg";
import NewBadge from "./icons/badge-new.png"
import Spinner from "./icons/spinner.svg";

import LogoMarkRed from "./icons/logo-mark-red.svg"
import WatchLive from "./icons/watch-live.svg"
import WatchLiveSignIn from "./icons/watch-live-sign-in.svg"
import PlayIcon from "./icons/play-icon.svg"
import PlayIconSmall from "./icons/play-icon-sm.svg"

import SuccessIconSmall from "./icons/success-icon-sm.svg"

import DefaultLiveAsset from './live/default-live-asset.jpg';
import TitleUnderline from './live/title-underline.png';

import DishLogo from "./providers/dish_logo.png";
import ATTLogo from "./providers/att_logo.png";
import XfinityLogo from "./providers/xfinity_logo.png";
import DirectTVLogo from "./providers/dtv_logo.png";
import SpectrumLogo from "./providers/spectrum_logo.png";
import VerizonLogo from "./providers/verizon_logo.png";

const SVGImage = {
  ReelzNowLogo: ReelzNowLogo,
  ReelzNowLogoReverse: ReelzNowLogoReverse,
  Facebook: Facebook,
  Twitter: Twitter,
  Instagram: Instagram,
  Youtube: Youtube,
  Amazon: Amazon,
  Apple: Apple,
  Roku: Roku,
  Google: Google,

  Menu: Menu,
  Search: Search,
  ArrowLeftSmall: ArrowLeftSmall,

  PreviousArrow: PreviousArrow,
  PreviousArrowHover: PreviousArrowHover,
  NextArrow: NextArrow,
  NextArrowHover: NextArrowHover,
  CarouselDot: CarouselDot,
  CarouselDotActive: CarouselDotActive,

  ArrowLeft: ArrowLeft,
  ArrowLeftInactive: ArrowLeftInactive,
  ArrowLeftHover: ArrowLeftHover,
  ArrowRight: ArrowRight,
  ArrowRightInactive: ArrowRightInactive,
  ArrowRightHover: ArrowRightHover,
  Back: Back,

  NewBadge: NewBadge,
  LogoMarkRed: LogoMarkRed,
  WatchLive: WatchLive,
  WatchLiveSignIn: WatchLiveSignIn,
  PlayIcon: PlayIcon,
  PlayIconSmall: PlayIconSmall,
  RandomUnderline: RandomUnderline,
  Spinner: Spinner,

  SuccessIconSmall: SuccessIconSmall,

  DownloadApple,
  DownloadGoogle
};

const Image = {
  DefaultLiveAsset,
  TitleUnderline,
  DishLogo,
  ATTLogo,
  XfinityLogo,
  DirectTVLogo,
  SpectrumLogo,
  VerizonLogo
}

function RandomUnderline() {
  const rand =  Math.floor(Math.random() * (7 - 1) + 1);
  return require(`./icons/underlines/underline-${rand}.svg`)
}

const SVG = {
  Search: SearchComponent
};

export { SVGImage, SVG, Image };

import React from "react";
import Schedule from "../../components/Schedule/Schedule";
import "./SchedulePage.scss";

const SchedulePage: React.FunctionComponent<any> = () => {
  return (
    <div className="SchedulePage">
      <Schedule fixed/>
    </div>
  )
};

export default SchedulePage;

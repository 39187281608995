//@ts-ignore
const concurrencyStreamURL = process.env.REACT_APP_ADOBE_CONCURRENCY;

export default class AdobeConcurrencyManager {
  private _config: object
  private _currentProvider: string
  private _currentUser: string
  private _sessionId: string
  private _interval: number
  private _inSession: boolean
  private _metadata: Array<string>
  private _heartbeatTimeout?: object

  constructor(config: any) {
    this._config = config;
    this._currentProvider = config.provider;
    this._currentUser = encodeURIComponent(config.userId)
    this._sessionId = '';
    this._interval = 10000;
    this._inSession = false;
    this._metadata = [];
  }
  async init(onSuccess, onError) {
    await this.fetchApi({
      path: "metadata",
      type: "GET",
      success: (response) => {
        this._metadata = response;
        let hbaStatus = sessionStorage.getItem("hba_status");
        if (this._metadata.indexOf("hba_status") > -1 &&
        hbaStatus !== undefined && hbaStatus !== null) {
          // @ts-ignore
          this._config.hba = hbaStatus;
        }
      },
      error: onError
    })
    // Package is determined by resource_id
    const resourceId = sessionStorage.getItem("resourceId");
    // Assigns the appropriate package if the resourceId is a SlingTV one.
    const packageId = (resourceId === "BReelz") ? "blue-3" : (resourceId === "OReelz" ? "orange-1" : "reelz");
    // Currently only package is a required metadata field. In the future we might need
    // to pass additional metadata fields and will need to check to make sure they are
    // present in the config and will pass them on as formdata
    // const formData = new URLSearchParams("package=orange-1");
    let payload = "package=" + packageId;
    // @ts-ignore
    if (this._config.hba !== undefined) payload += "&hba_status=" + this._config.hba;

    this.fetchApi({
      path: `sessions/${this._currentProvider}/${this._currentUser}`,
      type: "POST",
      data: payload || "",
      success: (response) => {
        console.log(response)
        const { headers } = response;
        this._sessionId = headers.get("Location");
        const date = Date.parse(headers.get("Date")) as number;
        const expires = Date.parse(headers.get("Expires")) as number;
        this._interval = (expires - date) as number;
        this._inSession = true;
        window.addEventListener("beforeunload", async () => {
          await this.stop();
          return undefined;
        }, false);
        this.setHeartbeatTimer(Object.assign(this._config, {
          payload,
          onError
        }))
        if (onSuccess) {
          onSuccess();
        };
      },
      error: onError
    })
  }
  async stop() {
    console.log("Closing concurrency session");
    if (this._inSession) {
      this._inSession = false;
      this.xhrDelete({ path: `sessions/${this._currentProvider}/${this._currentUser}/${this._sessionId}` })
      return;
    }
  }
  private callHeartbeat(options) {
    if (this._inSession) {
      this.fetchApi({
        path: `sessions/${this._currentProvider}/${this._currentUser}/${this._sessionId}`,
        type: "POST",
        data: options.payload || "",
        success: (response) => {
          this._inSession = true;
          this.setHeartbeatTimer(options)
        },
        error: options.onError
      })
    }
  }
  private async xhrDelete(options) {
    const xhr = new XMLHttpRequest();
    xhr.open("DELETE", `${concurrencyStreamURL}/${options.path}`, false);
    const base64data = btoa(`${process.env.REACT_APP_ADOBE_CONCURRENCY_ID}:`)
    xhr.setRequestHeader("Authorization", `Basic ${base64data}`);
    xhr.send();
  }
  private async fetchApi(options, attempt429error = 0) {
    const { success, error, onComplete } = options;
    const url = `${concurrencyStreamURL}/${options.path}`;
    const base64data = btoa(`${process.env.REACT_APP_ADOBE_CONCURRENCY_ID}:`)
    const config = {
      method: options.type || "GET",
      headers: {
        "Authorization": `Basic ${base64data}`
      },
      cache: "no-cache"
    };
    if (options.type == "POST") {
      config["body"] = options.data
      config.headers["Content-Type"] = "application/x-www-form-urlencoded"
    }
    //@ts-ignore
    return fetch(url, config).then(async (response) => {
      let data;
      if (response.ok) {
        if (response.status === 202) {
          if (success) {
            return success(response);
          }
          return {}
        } else {
          data = await response.json();
          if (success) {
            return success(data);
          }
          return data;
        }
      } else {
        data = await response.json();

        if (response.status === 429) {
          console.error(`429 Too Many Requests, attempt ${attempt429error + 1} (of 2)`)
          if (attempt429error === 1) {
            if (error) {
              return error(data)
            } else {
              return console.log("An unhandled error occurred while managing concurrency: ", response, data)
            }
          }

          // Wait 1 minute and try again
          await new Promise(resolve => setTimeout(resolve, 60 * 1000))
          return await this.fetchApi(options, 1)
        } else if (response.status === 409) {
          console.log("Too many streams at once");
          if (error) {
            return error(data)
          };
        } else {
          console.log("An unhandled error occurred while managing concurrency: ", response, data)
        }

        return data;
      }
    })
  }
  private setHeartbeatTimer(options) {
    this._heartbeatTimeout = setTimeout(() => {
      this.callHeartbeat(options);
    }, this._interval)
  }

}


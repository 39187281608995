import React from "react";
import classnames from "classnames";
import "./Filter.scss";

export default class Filter extends React.Component<any, any> {
  static defaultProps = {
    options: []
  }

  constructor(props) {
    super(props);
    this.state = {
      selected: 0
    }
  }

  componentDidMount() {
    this.onChange(0);
  }

  onChange = (index) => {
    const { onChange, options } = this.props;
    this.setState({ selected: index })
    onChange && onChange(options[index].value);
  }

  render() {
    const { options } = this.props;
    const { selected } = this.state;
    return (
      <div className="Filter">
        <ul>
          {
            options.map((option, index) => {
              let className = classnames(["Filter-item", { selected: selected == index }]);
              return <li className={className} key={index} onClick={() => this.onChange(index)}>{option.label}</li>
            })
          }
        </ul>
      </div>
    )
  }
};

import React from "react";
import { SVGImage, Image } from "../../assets/images";
import "./Slide.scss";
import MultiLineDiv from "../MultiLineDiv/MultiLineDiv";
import { Responsive, Breakpoint } from "../Breakpoints";
import AppLink from "../AppLink/AppLink";

const Slide: React.FunctionComponent<any> = ({ header, subHeader, info, description, image, color, url, contentType }) => {
  return (
    <AppLink load to={url}>
      <div className="Slide">
        <div className="image" style={{ backgroundImage: `url(${image})` }}>
          { contentType == "EPISODE" && <div className="play-icon"><img src={SVGImage.PlayIcon} /></div> }
        </div>
        <div className="metadata">
          <div className="metadata-wrapper">
            <MultiLineDiv maxLines={2} className="header">{header}</MultiLineDiv>
            <img src={Image.TitleUnderline} className="title-underline" />
            { subHeader && <div className="subheader">{subHeader}</div> }
            { info && <div className="info">{info}</div> }
            { description &&
              <Responsive>
              { breakpoint => {
                const maxLines = [Breakpoint.Desktop, Breakpoint.TabletWide].includes(breakpoint) ? 3 :
                  [Breakpoint.Tablet, Breakpoint.Mobile].includes(breakpoint) ? 2 :
                  undefined;

                  return (
                    <React.Fragment>
                      <MultiLineDiv maxLines={maxLines} className="description">{description}</MultiLineDiv>
                    </React.Fragment>
                  )
              }}
              </Responsive>
            }
          </div>
        </div>
      </div>
    </AppLink>
  )
}

export default Slide;

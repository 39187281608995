import React from "react";
import Schedule from "../Schedule/Schedule";
import Fetch from "../Fetch/Fetch";
import "./LiveStreamHeader.scss";
import { SVGImage } from "../../assets/images";
import { Icon, ICONS } from "@brightcove/studio-components";
import moment from "moment-timezone";
import { getCurrentTimeZoneTime, getDate } from "../../utils";

const PlayerID = "liveStreamPlayer";
const streamFrameEl = () => document.getElementById(PlayerID) as HTMLIFrameElement
const streamFrameDocument = () => streamFrameEl();
const headerEl = () => {
  return document.querySelector(".LiveStreamHeader");
};
const controlsEl = () => {
  let sfd = streamFrameEl();
  return sfd && sfd.querySelector(".vjs-control-bar");
}

export default class LiveStreamSchedule extends React.Component<any, any> {
  scheduleEl;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      lastCheck: new Date()
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.setScheduleHeight);
    window.addEventListener("orientationchange", this.setScheduleHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setScheduleHeight);
    window.removeEventListener("orientationchange", this.setScheduleHeight);
  }

  componentDidUpdate(prevProps, prevState) {
    let currentTime = new Date();
    let lastCheck = this.state.lastCheck;

    let elapsedMinutes = moment(currentTime).diff(lastCheck, "minutes");
    if(elapsedMinutes >= 1)
      this.setState({ lastCheck: new Date() })

    if(!prevState.open && this.state.open) {
      this.setScheduleHeight();
    }
  }

  setScheduleHeight = () => {
    if(this.scheduleEl) {
      const maxHeight = (document.getElementById(PlayerID) as HTMLIFrameElement).getBoundingClientRect().height;
      const upperSectionHeight = headerEl()!.getElementsByClassName("Schedule-upper-section")[0].getBoundingClientRect().height;
      const entriesEl = headerEl()!.getElementsByClassName("Schedule-entries")[0] as HTMLElement;
      entriesEl.style.height = `${maxHeight - upperSectionHeight}px`;
    }
  }

  toggleSchedule = (scheduleData) => {
    if(scheduleData != undefined) {
      this.setState((prevState) => ( { open: !prevState.open, scheduleData } ));
    } else {
      this.setState((prevState) => ( { open: !prevState.open } ));
    }
  }

  getCurrentShow = (schedule) => {
    const currentTime = getCurrentTimeZoneTime("EST");

    for(let i=0; i<schedule.length; i++) {
      let dataEntry = schedule[i];
      let nextDataEntry = (i < (schedule.length - 1)) && schedule[i + 1];

      const startTime = getDate(dataEntry)!;
      const endTime = getDate(nextDataEntry) || startTime.add(1, "hours");

      if(currentTime.isBetween(startTime, endTime)) {
        return dataEntry;
      }
    }
    return {};
  }

  render() {
    const { open, scheduleData } = this.state;
    return (
      <React.Fragment>
        <div className="LiveStreamHeader">
          <div className="LiveStreamHeader-back" onClick={() => window.location.replace("/")}><img src={SVGImage.Back}/></div>
          <Fetch url={process.env.REACT_APP_SCHEDULE_URL} addMiddlewareHeaders>
          {(loading, errors, data) => {
            const currentShow = data && data.schedule && this.getCurrentShow(data.schedule) || {};
            const currentShowTimeslot = currentShow && getDate(currentShow);

            return (
              (loading || errors) ?
                null :
                open ?
                  <div className="LiveStreamHeader-close-schedule" onClick={this.toggleSchedule}>
                    <Icon name={ICONS.CANCEL}/>
                  </div> :
                <React.Fragment>
                <div className="LiveStreamHeader-info">
                  <div className="show-name">{currentShow.show_title}</div>
                  <div className="episode-name">{currentShow.program_name}</div>
                  <div className="episode-timeslot">{currentShowTimeslot && moment(currentShowTimeslot).format("h:mm A")}</div>
                </div>
                <div className="LiveStreamHeader-schedule" onClick={() => this.toggleSchedule(data.schedule)}>Schedule</div>
                </React.Fragment>
            )
          }}
          </Fetch>
        </div>
        { open &&
          <Schedule ref={node => this.scheduleEl = node} header="Schedule" data={scheduleData} showArrows embedded />
        }
      </React.Fragment>
    )
  }
}

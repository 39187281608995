import React from "react";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import { withRouter } from "react-router-dom";
import { getCookie } from "../../utils";
import AuthManager from "../../utilities/authUtilities";

interface FetchProps {
  url?: string;
  method: string;
  body?: any;
  query?: any;
  options?: any;
  children: (loading?: boolean, errors?: any[], data?: any, status?: number, refetch?: Function) => React.ReactNode;
  toggleMaintenance: (isActive: boolean, message?: string) => void;
  addMiddlewareHeaders?: boolean;
  store: any;
  location: any;
  authzBeforeRequest?: boolean;
};

class Fetch extends React.Component<FetchProps, any> {
  static defaultProps = {
    method: "GET"
  }

  constructor(props) {
    super(props);
    this.state = {
      authzStatus: -1,
      loading: false,
      errors: undefined,
      data: undefined,
      status: undefined
    };
  }

  // Passes the userType, e.g. "adobe" to the AuthManager
  checkAuthz = (userType) => {
    // sets the checkedAuthz state immediately, then performs the authz check
    this.setState({
      authzStatus: 0
    }, () => {
      // Callback currently only handles Adobe events. If we ever add additional ones
      // then we can extend it or abstract it furthur
      AuthManager.CheckAuthorization(userType, this.props.store.selectedProvider.MVPD, "").then(async ({ eventType, data }) => {
        // Of the events/callbacks Adobe's checkAuthz function invokes, we are only interested in
        // the two below.
        switch (eventType) {
          case "SET_TOKEN":
            let response = await fetch(`${process.env.REACT_APP_MIDDLEWARE_URL}/authenticate-adobe`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              credentials: "include",
              body: JSON.stringify({ token: data.data.token, adobeID: this.props.store.userID || sessionStorage.getItem("userID")  })
            });

            this.setState({ authzStatus: 1 },
            () => this.performFetch()); break;
          case "TOKEN_REQUEST_FAILED":
            console.error(data.data.requestErrorDetails);
            break;
        }
      });
    })
  }

  componentDidMount() {
    const { store, authzBeforeRequest } = this.props;
    let jwt = getCookie("jwt");
    this.performFetch();

    if (!jwt && authzBeforeRequest && store && store.authenticationStatus > 0) {
      this.checkAuthz(store.userType);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { store, authzBeforeRequest } = nextProps;
    let jwt = getCookie("jwt");

    if (!jwt && authzBeforeRequest && store && store.authenticationStatus > 0 &&
      store.userType &&
      store.selectedProvider && store.selectedProvider.MVPD &&
      nextState.authzStatus === -1) {
      this.checkAuthz(store.userType);
    }
    return true
  }

  componentDidUpdate(prevProps) {
    if(prevProps.url != this.props.url) {
      this.performFetch();
    }
  }

  enableGeoRestriction() {
    //window.location.pathname = "/georestriction";
  }

  getQueryUrl = () => {
    const { url, query } = this.props;
    let queryVars: string[] = [];

    if(!url)
      return;

    for(let key in query) {
      queryVars.push(`${key}=${query[key]}`);
    }
    return `${url}?${queryVars.join("&")}`;
  }

  performFetch = () => {
    const { url, method, body, query, options, toggleMaintenance, addMiddlewareHeaders } = this.props;
    const resolvedUrl = query && this.getQueryUrl() || url;
    let params: any = { method, headers: {}, ...options };

    if(addMiddlewareHeaders) {
      params.headers = {
        ...params.headers,
        "user-platform": "Web",
        "user-language": "en",
        "content-catalog": process.env.REACT_APP_CATALOG
      }
    }

    if(!resolvedUrl) {
      this.setState({ loading: false });
      return;
    }


    if(method == "POST") {
      params.headers["Content-Type"] = "application/json"
      if(body) params.body = JSON.stringify(body);
    }

    this.setState({ loading: true });

    fetch(resolvedUrl!, params)
    .then(async response => {
      let status = response.status;
      let loading = false;
      let contentType = response.headers.get('content-type');
      let data;

      if(contentType && contentType.includes('application/json')) {
        data = await response.json();
        if(response.ok) {
          this.setState({ loading, data, status });
        }
        else if(response.status == 503) {
          toggleMaintenance(true, data.message);
        } else if(response.status == 403) {
          this.enableGeoRestriction();
        } else {
          this.setState({
            loading,
            ...data, // contains `data` and `errors` fields
            status
          })
        }
      }
      else {
        data = await response.text();
        if(response.ok) {
          this.setState({ loading, data, status });
        }
        else {
          this.setState({
            loading,
            status,
            errors: [{ message: data }]
          })
        }
      }
    })
    .catch(err => {
      this.setState({
        loading: false,
        errors: [{ message: err.message }]
      })
      this.enableGeoRestriction();
    })
  }

  render() {
    const { loading, errors, data, status } = this.state;
    const { children } = this.props;
    return children(loading, errors, data, status, this.performFetch);
  }
}

export default compose<any, any, any>(withRouter, withStore)(Fetch);
